import React from 'react'

import { Switch, Route, Redirect, useRouteMatch, useHistory, useParams } from 'react-router-dom'

import CompletionScreens from 'components/CompletionScreens/CompletionScreens'
import ErrorState from 'components/ErrorState'
import SelectLanguageScreen from 'components/SelectLanguageScreen'
import Survey, { PublicQuestions } from 'components/Survey/Survey'
import WelcomeScreen from 'components/WelcomeScreen'
import apolloClient from 'config/apollo'
import {
  BenchmarkCodeType,
  SurveyTypeEnum,
  WelcomeMessage,
  LanguageType,
  TranslationFragment,
  ParticipantSourceTypeInput,
  SurveyProductTypeEnum,
  PublicConstantsDocument,
  PublicConstantsQuery,
  SurveyTransitionTextType,
} from 'generated/graphql'
import { useTranslations } from 'locales'
import { isDesktopBrowser } from 'utils'
import { SurveyDisplayStrategy, URLS } from 'utils/constants'
import { LOCTypeEnum } from 'utils/generatedFrontendConstants'
import { ReviewSites } from 'utils/types'

type Props = {
  surveySubmitted?: boolean
  participantIsExpired: boolean
  surveyType: SurveyTypeEnum
  surveyProductType: SurveyProductTypeEnum
  isOpenLinkSurvey: boolean
  hasConfidentialResults: boolean
  welcomeMessage: WelcomeMessage
  participantId?: string
  questions: PublicQuestions
  levelOfCare: LOCTypeEnum
  templates: { [key: string]: string }
  responses: { [key: string]: string }
  updateResponses?(response: string, code: string): void // For updating preview responses
  onlineReviewSites?: ReviewSites
  isPreview?: boolean
  languages: LanguageType[]
  translations: TranslationFragment[]
  transitionTexts: SurveyTransitionTextType[]
  source?: ParticipantSourceTypeInput
}
const SurveyRouter: React.FC<Props> = ({
  surveySubmitted,
  participantIsExpired,
  surveyType,
  surveyProductType,
  isOpenLinkSurvey,
  hasConfidentialResults,
  welcomeMessage,
  participantId,
  questions: initialQuestions,
  levelOfCare,
  templates,
  responses,
  updateResponses,
  onlineReviewSites,
  isPreview,
  languages,
  translations,
  transitionTexts,
  source,
}) => {
  const { installCustomTranslations } = useTranslations({ useSuspense: false })
  installCustomTranslations(translations)

  const { path, url: theUrl } = useRouteMatch()
  const history = useHistory()
  // This router can be accessed either at the beginning of a standard, closed-link survey,
  // or in the middle of an open-link survey, after the participant has answered personal info questions.
  // For open-link surveys, the surveyStartUrl should send them straight to the survey questions,
  // since they have already seen the language selector and welcome message.
  let surveyStartUrl

  /* TODO: AI-2269 Remove up to line 83, it's only kept for backward compatibility */
  let url = theUrl
  const { sourceFromUrl } = useParams<{
    sourceFromUrl: ParticipantSourceTypeInput
  }>()
  if (
    sourceFromUrl &&
    !Object.values(ParticipantSourceTypeInput).includes(sourceFromUrl as ParticipantSourceTypeInput)
  ) {
    url = theUrl.substring(0, theUrl.lastIndexOf('/'))
  }

  const isPhoneSurvey = sourceFromUrl === ParticipantSourceTypeInput.P

  let nextURLAfterLanguageSelection = URLS.WELCOME
  if (isPhoneSurvey) {
    nextURLAfterLanguageSelection = URLS.TAKE_SURVEY
  }

  if (isOpenLinkSurvey) {
    surveyStartUrl = URLS.TAKE_SURVEY
  } else {
    // For non open-link surveys, the surveyStartUrl depends on the presence of alternative languages.
    surveyStartUrl = languages.length > 1 ? URLS.SELECT_LANGUAGE : nextURLAfterLanguageSelection
  }
  const onlineReviewQuestion = initialQuestions.find(
    q => q.benchmarkCode === BenchmarkCodeType.ONLINE_REVIEW,
  )
  const testimonialsReviewQuestion = initialQuestions.find(
    q => q.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_REVIEW,
  )
  let questions = initialQuestions.filter(q => q.benchmarkCode !== BenchmarkCodeType.ONLINE_REVIEW)
  if (isOpenLinkSurvey) {
    // Open link surveys will not show the TESTIMONIALS_PRIVACY question.
    // The answer for this question will be automatically filled in by the backend
    questions = questions.filter(q => q.benchmarkCode !== BenchmarkCodeType.TESTIMONIALS_PRIVACY)
  }

  const completionScreenProps = {
    onlineReviewSites,
    onlineReviewQuestion,
    testimonialsReviewQuestion,
    responses,
    isPreview,
    languages,
    isPhoneSurvey,
  }
  if (participantIsExpired) {
    return <ErrorState message="Error: This survey has expired." />
  }
  let surveyDisplayStrategy = SurveyDisplayStrategy.QUESTION_BY_QUESTION
  let questionBatchSize = 0
  if (
    (isPhoneSurvey || surveyProductType === SurveyProductTypeEnum.EMPLOYEE) &&
    isDesktopBrowser() // Mobile devices will always use question by question for a better UX.
  ) {
    surveyDisplayStrategy = SurveyDisplayStrategy.QUESTION_BATCH
    if (isPhoneSurvey) {
      questionBatchSize = Number.MAX_VALUE
    } else {
      questionBatchSize = 5
    }
  }

  const data = apolloClient.readQuery<PublicConstantsQuery>({ query: PublicConstantsDocument })
  const questionInstructions = data?.publicConstants?.questionInstructions || []

  let routes
  if (surveySubmitted) {
    routes = [
      <Route
        key="end"
        path={`${path}/${URLS.COMPLETE}`}
        render={() => <CompletionScreens {...completionScreenProps} />}
      />,
      <Redirect key="redirect" to={`${url}/${URLS.COMPLETE}`} />,
    ]
  } else {
    routes = [
      <Route
        key="survey"
        path={`${path}/${URLS.TAKE_SURVEY}`}
        render={() => (
          <Survey
            participantId={participantId}
            surveyProductType={surveyProductType}
            surveyType={surveyType}
            isOpenLinkSurvey={isOpenLinkSurvey}
            hasConfidentialResults={hasConfidentialResults}
            // The online review uses a custom UI
            questions={questions.filter(q => q.benchmarkCode !== BenchmarkCodeType.ONLINE_REVIEW)}
            templates={templates}
            responses={responses}
            levelOfCare={levelOfCare}
            updateResponses={updateResponses}
            languages={languages}
            source={source}
            surveyDisplayStrategy={surveyDisplayStrategy}
            questionBatchSize={questionBatchSize}
            questionInstructions={questionInstructions}
            transitionTexts={transitionTexts}
          />
        )}
      />,
      <Route
        key="language"
        path={`${path}/${URLS.SELECT_LANGUAGE}`}
        render={() => (
          <SelectLanguageScreen
            goNext={() => history.push(nextURLAfterLanguageSelection)}
            languages={languages}
          />
        )}
      />,
      <Route
        key="welcome"
        path={`${path}/${URLS.WELCOME}`}
        render={() => (
          <WelcomeScreen
            surveyType={surveyType}
            welcomeMessage={welcomeMessage}
            goBack={
              languages.length > 1
                ? () => history.push(`${url}/${URLS.SELECT_LANGUAGE}`)
                : undefined
            }
            goNext={() => history.push(`${url}/${URLS.TAKE_SURVEY}`)}
            languages={languages}
            templates={templates}
          />
        )}
      />,
      <Route
        key="end"
        path={`${path}/${URLS.COMPLETE}`}
        render={() => <CompletionScreens {...completionScreenProps} />}
      />,
      <Redirect key="redirect" to={`${url}/${surveyStartUrl}`} />,
    ]
  }
  return <Switch>{routes}</Switch>
}

export default SurveyRouter
