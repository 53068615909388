import { PublicQuestion, PublicQuestions } from 'components/Survey/Survey'
import { BenchmarkCodeType, QResidentFocus, SurveyTransitionTextKey } from 'generated/graphql'
import { TranslationKey } from 'locales'
import { LOCTypeEnum } from 'utils/generatedFrontendConstants'

export type TransitionTextByKeyType = Record<SurveyTransitionTextKey, Transition>

export class Transition {
  text: TranslationKey

  buttonLabel?: TranslationKey

  constructor(text: TranslationKey, buttonLabel?: TranslationKey) {
    this.text = text
    this.buttonLabel = buttonLabel
  }
}
// Insert transition screens into the "questions" list, so we can use them in order.
// These rules are very ad-hoc so it would be difficult to use in a configurable way.
export const populateEngagementTransitions = (
  questions: PublicQuestion[],
  levelOfCare: LOCTypeEnum,
  hasConfidentialResults: boolean,
  transitionTexts: TransitionTextByKeyType,
) => {
  const questionsWithTransitions: Array<PublicQuestion | Transition> = []
  let currentFocus: PublicQuestion['residentFocus'] = QResidentFocus.OVERALL_SATISFACTION

  // Before the survey
  if (hasConfidentialResults) {
    questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.THIRD_PARTY_CONFIDENTIAL])
  } else {
    questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.THIRD_PARTY])
  }

  questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.VERBATIM_NAME])

  if (![LOCTypeEnum.AFFORDABLE_HOUSING, LOCTypeEnum.INDEPENDENT_LIVING].includes(levelOfCare)) {
    questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.NATIONAL_INITIATIVE])
  }

  questions.forEach(question => {
    // After overall satisfaction question
    if (
      currentFocus === QResidentFocus.OVERALL_SATISFACTION &&
      question.residentFocus === QResidentFocus.SERVICE_AREAS
    ) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.EXPERIENCES_NEXT])
      questionsWithTransitions.push(
        transitionTexts[SurveyTransitionTextKey.APPLICABLE_QUESTIONS_CONTINUE],
      )
    } // Before demographics questions
    else if (
      question.residentFocus === QResidentFocus.DEMOGRAPHICS &&
      currentFocus !== QResidentFocus.DEMOGRAPHICS
    ) {
      questionsWithTransitions.push(
        transitionTexts[SurveyTransitionTextKey.KNOW_YOU_BETTER_RESIDENTS],
      )
    } // Before open-ended questions
    else if (
      question.residentFocus === QResidentFocus.OPEN_ENDED &&
      currentFocus !== QResidentFocus.OPEN_ENDED
    ) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.OVERALL_EXPERIENCES])
    } // Before Companionship question
    else if (question.benchmarkCode === BenchmarkCodeType.COMPANIONSHIP) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.GENERAL_FEELING])
    } // Before Assistance question
    else if (question.benchmarkCode === BenchmarkCodeType.ASSISTANCE) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.ALMOST_DONE])
    }
    currentFocus = question.residentFocus
    questionsWithTransitions.push(question)
  })
  // After last question
  questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.DONE])
  return questionsWithTransitions
}

export const populatePulseTransitions = (
  questions: PublicQuestion[],
  hasConfidentialResults: boolean,
  transitionTexts: TransitionTextByKeyType,
) => {
  const questionsWithTransitions: Array<PublicQuestion | Transition> = []
  let currentFocus: PublicQuestion['residentFocus'] = QResidentFocus.OVERALL_SATISFACTION

  // Before the survey
  if (hasConfidentialResults) {
    questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.THIRD_PARTY_CONFIDENTIAL])
  } else {
    questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.THIRD_PARTY])
  }

  questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.VERBATIM_NAME_CONTINUE])
  questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.APPLICABLE_QUESTIONS])

  questions.forEach(question => {
    // Before demographics questions
    if (
      question.residentFocus === QResidentFocus.DEMOGRAPHICS &&
      currentFocus !== QResidentFocus.DEMOGRAPHICS
    ) {
      questionsWithTransitions.push(
        transitionTexts[SurveyTransitionTextKey.KNOW_YOU_BETTER_RESIDENTS],
      )
    }
    // Before Testimonials question
    else if (question.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_APPROVAL) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.ALMOST_DONE])
    }
    currentFocus = question.residentFocus
    questionsWithTransitions.push(question)
  })
  // After last question
  questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.DONE])
  return questionsWithTransitions
}

export const populateDischargeOrEndOfService = (
  questions: PublicQuestion[],
  levelOfCare: LOCTypeEnum,
  transitionTexts: TransitionTextByKeyType,
) => {
  const questionsWithTransitions: Array<PublicQuestion | Transition> = []
  let currentFocus: PublicQuestion['residentFocus'] = QResidentFocus.OVERALL_SATISFACTION

  // Before the survey
  questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.THIRD_PARTY_START])

  if (
    ![LOCTypeEnum.AFFORDABLE_HOUSING, LOCTypeEnum.INDEPENDENT_LIVING].includes(levelOfCare) &&
    !questions.find(q => q.benchmarkCode === BenchmarkCodeType.NPS_RECOMMEND)
  ) {
    questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.NATIONAL_INITIATIVE])
  }
  questions.forEach(question => {
    // After overall satisfaction question
    if (
      currentFocus === QResidentFocus.OVERALL_SATISFACTION &&
      question.residentFocus === QResidentFocus.SERVICE_AREAS
    ) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.EXPERIENCES_NEXT])
      questionsWithTransitions.push(
        transitionTexts[SurveyTransitionTextKey.APPLICABLE_QUESTIONS_CONTINUE],
      )
    } // Before demographics questions
    else if (
      currentFocus !== QResidentFocus.DEMOGRAPHICS &&
      question.residentFocus === QResidentFocus.DEMOGRAPHICS &&
      ![BenchmarkCodeType.MEDICAL_NEEDS, BenchmarkCodeType.LEFT_REASON].includes(
        question.benchmarkCode as BenchmarkCodeType,
      )
    ) {
      questionsWithTransitions.push(
        transitionTexts[SurveyTransitionTextKey.KNOW_YOU_BETTER_EVERYONE],
      )
    } // Before reason for leaving and open-ended questions
    else if (question.benchmarkCode === BenchmarkCodeType.LEFT_REASON) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.OVERALL_EXPERIENCES])
    } // Before the Assistance question for clients and Distance question for representatives
    else if (
      [BenchmarkCodeType.ASSISTANCE, BenchmarkCodeType.DISTANCE].includes(
        question.benchmarkCode as BenchmarkCodeType,
      )
    ) {
      questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.ALMOST_DONE])
    }
    currentFocus = question.residentFocus
    questionsWithTransitions.push(question)
  })
  // After last question
  questionsWithTransitions.push(transitionTexts[SurveyTransitionTextKey.DONE])
  return questionsWithTransitions
}

export const populateCustomTransitions = (
  questions: PublicQuestions,
  transitionTexts: TransitionTextByKeyType,
) => {
  return [...questions, transitionTexts[SurveyTransitionTextKey.DONE]]
}

export const populateNonConfidentialTransition = (
  questions: Array<PublicQuestion | Transition>,
) => {
  return [
    new Transition(
      'Please note, this survey isn’t anonymous. Staff at {{location_name}} will see that your feedback is from you.',
    ),
    ...questions,
  ]
}

export const populateOpenLinkTransition = (questions: Array<PublicQuestion | Transition>) => {
  return [
    new Transition(
      'Thank you! Next, we’d like to understand your overall experience at {{location_name}}.',
    ),
    ...questions,
  ]
}
