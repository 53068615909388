import { makeStyles } from '@material-ui/core'

import { desktopStyle } from 'utils'

export default makeStyles(() => ({
  screenContainer: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  questionBlock: {
    height: '70%',
    paddingTop: '4%',
    paddingLeft: '5%',
    paddingRight: '5%',
    ...desktopStyle({
      paddingLeft: '28%',
      paddingRight: '28%',
    }),
  },
  singlePageQuestionBlock: {
    overflow: 'hidden',
  },
  footerButton: {
    position: 'fixed',
    bottom: '12%',
    width: '90%',
    ...desktopStyle({
      bottom: '15%',
      position: 'static',
      marginTop: '5%',
      width: '100%',
    }),
    '& >button': {
      ...desktopStyle({
        width: 150,
      }),
      width: '100%',
    },
  },
}))
