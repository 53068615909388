import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
};

export type ActionItemNode = {
   __typename?: 'ActionItemNode';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  actionPlan: ActionPlanNode;
  statement?: Maybe<FrozenQuestionType>;
  /** A Custom Statement is determined by plain text and is not linked to an actual FrozenQuestion. */
  customStatement: Scalars['String'];
  customStatementProductType?: Maybe<SurveyProductTypeEnum>;
  positive: Scalars['Int'];
  inconsistent: Scalars['Int'];
  negative: Scalars['Int'];
  actionItemTasks: Array<ActionItemTaskNode>;
};

export type ActionItemsCountsType = {
   __typename?: 'ActionItemsCountsType';
  activeStatements: Scalars['Int'];
  notStarted: Scalars['Int'];
  inProgress: Scalars['Int'];
  onHold: Scalars['Int'];
  complete: Scalars['Int'];
  incomplete: Scalars['Int'];
};

export type ActionItemTaskCommentInput = {
  uuid?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  mentions?: Maybe<Array<UserCommentMentionInput>>;
};

export type ActionItemTaskCommentNode = Node & {
   __typename?: 'ActionItemTaskCommentNode';
  uuid: Scalars['ID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  text: Scalars['String'];
  user: UserNode;
  /** The ID of the object. */
  id: Scalars['ID'];
  mentions: Array<UserCommentMentionType>;
};

export type ActionItemTaskCommentNodeConnection = {
   __typename?: 'ActionItemTaskCommentNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActionItemTaskCommentNodeEdge>>;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ActionItemTaskCommentNode` and its cursor. */
export type ActionItemTaskCommentNodeEdge = {
   __typename?: 'ActionItemTaskCommentNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ActionItemTaskCommentNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ActionItemTaskInput = {
  text: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  status?: Maybe<TaskStatusEnum>;
  dueDate?: Maybe<Scalars['Date']>;
};

export type ActionItemTaskNode = {
   __typename?: 'ActionItemTaskNode';
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  task: Scalars['String'];
  status: TaskStatusEnum;
  dueDate?: Maybe<Scalars['Date']>;
  /** Free text to add the owner of a task. */
  owner: Scalars['String'];
  actionItem: ActionItemNode;
  actionPlan?: Maybe<ActionPlanNode>;
  comments: ActionItemTaskCommentNodeConnection;
};


export type ActionItemTaskNodeCommentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ActionPlanActivityType = {
   __typename?: 'ActionPlanActivityType';
  pastDays: Scalars['Int'];
  activityCount: Scalars['Int'];
};

export type ActionPlanCountsType = {
   __typename?: 'ActionPlanCountsType';
  employee?: Maybe<ActionItemsCountsType>;
  resident?: Maybe<ActionItemsCountsType>;
  custom?: Maybe<ActionItemsCountsType>;
};

export type ActionPlanNode = Node & {
   __typename?: 'ActionPlanNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  uuid: Scalars['ID'];
  owner: UserNode;
  organization: OrganizationType;
  actionItems: Array<ActionItemNode>;
  actionItemTasks: Array<ActionItemTaskNode>;
  created: Scalars['DateTime'];
  usersWithAccess: Array<UserNode>;
  surveyTi?: Maybe<SurveyNode>;
  surveyResidentEngagement?: Maybe<SurveyNode>;
  progress: ActionPlanCountsType;
  lastUpdated: Scalars['DateTime'];
  lastSurveyData?: Maybe<LastSurveyData>;
};


export type ActionPlanNodeLastSurveyDataArgs = {
  surveyType?: Maybe<SurveyTypeEnum>;
};

export type ActionPlanNodeConnection = {
   __typename?: 'ActionPlanNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActionPlanNodeEdge>>;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ActionPlanNode` and its cursor. */
export type ActionPlanNodeEdge = {
   __typename?: 'ActionPlanNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ActionPlanNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum ActionPlansSortByEnum {
  OWNER = 'OWNER',
  FOLLOW = 'FOLLOW',
  UPDATED = 'UPDATED'
}

export type ActionPlanSurveyInput = {
  surveyUuid: Scalars['String'];
  surveyType: ActionPlanSurveyType;
};

export enum ActionPlanSurveyType {
  TI = 'TI',
  RESIDENT_ENGAGEMENT = 'RESIDENT_ENGAGEMENT'
}

export type AddActionPlanCustomStatement = {
   __typename?: 'AddActionPlanCustomStatement';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

/** Add a new Action Plan Item (represented by a statement actually) */
export type AddActionPlanStatements = {
   __typename?: 'AddActionPlanStatements';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type AddContactsUpload = {
   __typename?: 'AddContactsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type AddCultureBriefImages = {
   __typename?: 'AddCultureBriefImages';
  errors?: Maybe<Array<Scalars['String']>>;
  cultureBrief?: Maybe<CultureBriefType>;
};

export type AddParticipantsUpload = {
   __typename?: 'AddParticipantsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type AgentInstructionType = {
   __typename?: 'AgentInstructionType';
  text: Scalars['String'];
  kind?: Maybe<QKind>;
  question?: Maybe<QuestionType>;
};

export type AnalyticsIdentifiersType = {
   __typename?: 'AnalyticsIdentifiersType';
  isTestAccount: Scalars['String'];
  orgName: Scalars['String'];
  residentSolutionName?: Maybe<Scalars['String']>;
  solutionName?: Maybe<Scalars['String']>;
  birthYearRange?: Maybe<Scalars['String']>;
  levelOfCare?: Maybe<Scalars['String']>;
  residentRespondentType?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum BenchmarkCodeType {
  AI_DEPT = 'ai_dept',
  AI_IMPACT = 'ai_impact',
  AI_LOC = 'ai_loc',
  AI_LOCATION = 'ai_location',
  AI_RESIDENT_IMPACT = 'ai_resident_impact',
  AI_SHIFT = 'ai_shift',
  AI_WHERE_WORK = 'ai_where_work',
  APPLICATION_DATE = 'application_date',
  ARRIVAL_CLEANLINESS = 'arrival_cleanliness',
  ASSISTANCE = 'assistance',
  ASSISTANCE_TYPE = 'assistance_type',
  BEST_THING = 'best_thing',
  BUILDING = 'building',
  BUSINESS_SEGMENT = 'business_segment',
  CAMPUS = 'campus',
  CARE_QUESTIONS = 'care_questions',
  CARE_TERM_ID = 'care_term_id',
  CAREGIVER_TIMELINESS = 'caregiver_timeliness',
  CAREGIVING_1 = 'caregiving_1',
  CAREGIVING_2 = 'caregiving_2',
  CAREGIVING_COMMENTS = 'caregiving_comments',
  CLEANLINESS = 'cleanliness',
  CLIENT_EXTERNAL_ID = 'client_external_id',
  CLIENT_NAME = 'client_name',
  CLIENTS_MATCHING = 'clients_matching',
  COMMUNICATION = 'communication',
  COMPANIONSHIP = 'companionship',
  CORE_Q1_RECOMMEND = 'core_q1_recommend',
  CORE_Q2_STAFF = 'core_q2_staff',
  CORE_Q3_CARE = 'core_q3_care',
  CORE_Q4_FOOD = 'core_q4_food',
  CORE_Q5_DISCHARGE = 'core_q5_discharge',
  CORE_Q_SUMMARY = 'core_q_summary',
  DATE_OF_BIRTH = 'date_of_birth',
  DEPARTMENT_ID = 'department_id',
  DEPARTMENT_NAME = 'department_name',
  DIGNITY = 'dignity',
  DINING_1 = 'dining_1',
  DINING_2 = 'dining_2',
  DINING_3 = 'dining_3',
  DINING_4 = 'dining_4',
  DINING_COMMENTS = 'dining_comments',
  DINING_EXPERIENCE = 'dining_experience',
  DISCHARGE_REASON = 'discharge_reason',
  DISTANCE = 'distance',
  DIVISION_ID = 'division_id',
  DIVISION_NAME = 'division_name',
  DRIVE_FREQUENCY = 'drive_frequency',
  EMPLOYEE_LEFT_REASON = 'employee_left_reason',
  EMPLOYEE_OTHERS_LEFT_REASON = 'employee_others_left_reason',
  EMPLOYEES_TO_RECOGNIZE = 'employees_to_recognize',
  EMPLYOMENT_TERM_ID = 'emplyoment_term_id',
  FIRST_NAME = 'first_name',
  FLOOR = 'floor',
  FRANCHISE = 'franchise',
  FRIENDLINESS_AND_PROFESSIONALISM = 'friendliness_and_professionalism',
  GROUP = 'group',
  HEALTH = 'health',
  HIRE_DATE = 'hire_date',
  HOME_LIKE = 'home_like',
  HOUSEHOLD_SIZE = 'household_size',
  IGNORE = 'ignore',
  IMPROVEMENTS = 'improvements',
  INDIVIDUAL_NEEDS = 'individual_needs',
  IS_PARTICIPANT = 'is_participant',
  ISOLATED = 'isolated',
  JOB_TITLE = 'job_title',
  KNOW_WHAT_TO_EXPECT = 'know_what_to_expect',
  LAST_NAME = 'last_name',
  LEADERSHIP = 'leadership',
  LEADERSHIP_COMMENTS = 'leadership_comments',
  LEADERSHIP_RESPONSIVE = 'leadership_responsive',
  LEFT_COMMENTS = 'left_comments',
  LEFT_OUT = 'left_out',
  LEFT_REASON = 'left_reason',
  LENGTH_OF_STAY = 'length_of_stay',
  LICENSED_NURSING_1 = 'licensed_nursing_1',
  LICENSED_NURSING_2 = 'licensed_nursing_2',
  LICENSED_NURSING_COMMENTS = 'licensed_nursing_comments',
  LOCATION_ID = 'location_id',
  LONELINESS_FOLLOWUP = 'loneliness_followup',
  MARITAL_STATUS = 'marital_status',
  MEDICAL_NEEDS = 'medical_needs',
  MOBILE_PHONE = 'mobile_phone',
  MOD_LENGTH_OF_STAY = 'mod_length_of_stay',
  MOVE_IN = 'move_in',
  MOVE_OUT = 'move_out',
  NAME = 'name',
  NEXT_PLACE = 'next_place',
  NPS_RECOMMEND = 'nps_recommend',
  NPS_RECOMMEND_FOLLOWUP = 'nps_recommend_followup',
  ONE_CHANGE = 'one_change',
  ONE_CHANGE_JOB = 'one_change_job',
  ONE_CHANGE_SERVICES = 'one_change_services',
  ONE_IMPROVEMENT = 'one_improvement',
  ONLINE_REVIEW = 'online_review',
  OWNER = 'owner',
  PARTICIPANT_TYPE = 'participant_type',
  PAST_GOOD_FRIENDS = 'past_good_friends',
  PAST_LIFE_BALANCE = 'past_life_balance',
  PAST_RECOGNITION = 'past_recognition',
  PAST_SUPERVISOR_RELATIONSHIP = 'past_supervisor_relationship',
  PAST_TOP_APPRECIATION = 'past_top_appreciation',
  PAYOR_TYPE = 'payor_type',
  PERSONAL_EMAIL = 'personal_email',
  R_BIRTH_YEAR = 'r_birth_year',
  R_DATE_OF_BIRTH = 'r_date_of_birth',
  R_FIRST_NAME = 'r_first_name',
  R_GENDER = 'r_gender',
  R_LAST_NAME = 'r_last_name',
  R_MOBILE_PHONE = 'r_mobile_phone',
  R_PERSONAL_EMAIL = 'r_personal_email',
  R_RELATIONSHIP = 'r_relationship',
  R_SAMPLE_RECORD_ID = 'r_sample_record_id',
  R_SURVEY_CODE = 'r_survey_code',
  RATE_ACCOUNT_BILLING = 'rate_account_billing',
  RATE_ADMISSIONS = 'rate_admissions',
  RATE_BUILDING = 'rate_building',
  RATE_CLINIC = 'rate_clinic',
  RATE_COMMUNICATION_AND_SUPPORT = 'rate_communication_and_support',
  RATE_COMMUNITY = 'rate_community',
  RATE_DAY_CARE_ADULT = 'rate_day_care_adult',
  RATE_DAY_CARE_CHILDREN = 'rate_day_care_children',
  RATE_DENTIST_SPECIALISTS = 'rate_dentist_specialists',
  RATE_ENRICHMENT_ACTIVITIES = 'rate_enrichment_activities',
  RATE_ENRICHMENT_INTEREST = 'rate_enrichment_interest',
  RATE_ENRICHMENT_SCHEDULE = 'rate_enrichment_schedule',
  RATE_ENVIRONMENT_SERVICES = 'rate_environment_services',
  RATE_FOUNDATION_PHILANTHROPY = 'rate_foundation_philanthropy',
  RATE_FRONT_DESK = 'rate_front_desk',
  RATE_HOME_CARE = 'rate_home_care',
  RATE_HOME_HEALTH = 'rate_home_health',
  RATE_HOSPICE = 'rate_hospice',
  RATE_HOUSEKEEPING = 'rate_housekeeping',
  RATE_IT_INFORMATION_SYSTEMS = 'rate_it_information_systems',
  RATE_LANDSCAPING_GROUNDS = 'rate_landscaping_grounds',
  RATE_LAUNDRY = 'rate_laundry',
  RATE_MAINTENANCE = 'rate_maintenance',
  RATE_MARKET_STORE = 'rate_market_store',
  RATE_MARKETING_OR_ADMISSIONS = 'rate_marketing_or_admissions',
  RATE_MEDICAL = 'rate_medical',
  RATE_MEDICATION_TECHNICIANS = 'rate_medication_technicians',
  RATE_OCCUPATIONAL_THERAPY = 'rate_occupational_therapy',
  RATE_PARKING = 'rate_parking',
  RATE_PHYSICAL_THERAPY = 'rate_physical_therapy',
  RATE_RECOGNITION = 'rate_recognition',
  RATE_SAFETY = 'rate_safety',
  RATE_SALON = 'rate_salon',
  RATE_SERVICE_COORDINATORS = 'rate_service_coordinators',
  RATE_SOCIAL_WORK = 'rate_social_work',
  RATE_SPEECH_LANGUAGE_PATHOLOGY = 'rate_speech_language_pathology',
  RATE_SPIRITUAL_SERVICES = 'rate_spiritual_services',
  RATE_TRAINING = 'rate_training',
  RATE_TRANSPORTATION = 'rate_transportation',
  RATE_UNIT = 'rate_unit',
  RATE_WELLNESS_FITNESS = 'rate_wellness_fitness',
  READINESS = 'readiness',
  REGION_ID = 'region_id',
  REGION_NAME = 'region_name',
  RESIDENT_GROUP = 'resident_group',
  RESIDENT_RESPONDENT_TYPE = 'resident_respondent_type',
  SAFE = 'safe',
  SAFE_COMMENTS = 'safe_comments',
  SAMPLE_RECORD_ID = 'sample_record_id',
  SENIOR_BIRTH_YEAR = 'senior_birth_year',
  SENIOR_DATE_OF_BIRTH = 'senior_date_of_birth',
  SERVICE_AREA_COMMENTS = 'service_area_comments',
  STAFF_CONTACT = 'staff_contact',
  STATE = 'state',
  SUBTEAM = 'subteam',
  SUPERVISOR_ID = 'supervisor_id',
  SUPERVISOR_NAME = 'supervisor_name',
  SURVEY_CODE = 'survey_code',
  TEAM = 'team',
  TERMINATION_DATE = 'termination_date',
  TERMINATION_REASON = 'termination_reason',
  TERRITORY_CODE = 'territory_code',
  TESTIMONIALS_APPROVAL = 'testimonials_approval',
  TESTIMONIALS_PRIVACY = 'testimonials_privacy',
  TESTIMONIALS_REVIEW = 'testimonials_review',
  TI_1 = 'ti_1',
  TI_10 = 'ti_10',
  TI_11 = 'ti_11',
  TI_12 = 'ti_12',
  TI_13 = 'ti_13',
  TI_14 = 'ti_14',
  TI_15 = 'ti_15',
  TI_16 = 'ti_16',
  TI_17 = 'ti_17',
  TI_18 = 'ti_18',
  TI_19 = 'ti_19',
  TI_2 = 'ti_2',
  TI_20 = 'ti_20',
  TI_21 = 'ti_21',
  TI_22 = 'ti_22',
  TI_23 = 'ti_23',
  TI_24 = 'ti_24',
  TI_25 = 'ti_25',
  TI_26 = 'ti_26',
  TI_27 = 'ti_27',
  TI_28 = 'ti_28',
  TI_29 = 'ti_29',
  TI_3 = 'ti_3',
  TI_30 = 'ti_30',
  TI_31 = 'ti_31',
  TI_32 = 'ti_32',
  TI_33 = 'ti_33',
  TI_34 = 'ti_34',
  TI_35 = 'ti_35',
  TI_36 = 'ti_36',
  TI_37 = 'ti_37',
  TI_38 = 'ti_38',
  TI_39 = 'ti_39',
  TI_4 = 'ti_4',
  TI_40 = 'ti_40',
  TI_41 = 'ti_41',
  TI_42 = 'ti_42',
  TI_43 = 'ti_43',
  TI_44 = 'ti_44',
  TI_45 = 'ti_45',
  TI_46 = 'ti_46',
  TI_47 = 'ti_47',
  TI_48 = 'ti_48',
  TI_49 = 'ti_49',
  TI_5 = 'ti_5',
  TI_50 = 'ti_50',
  TI_51 = 'ti_51',
  TI_52 = 'ti_52',
  TI_53 = 'ti_53',
  TI_54 = 'ti_54',
  TI_55 = 'ti_55',
  TI_56 = 'ti_56',
  TI_57 = 'ti_57',
  TI_58 = 'ti_58',
  TI_59 = 'ti_59',
  TI_6 = 'ti_6',
  TI_60 = 'ti_60',
  TI_7 = 'ti_7',
  TI_8 = 'ti_8',
  TI_9 = 'ti_9',
  TI_BIRTH_YEAR = 'ti_birth_year',
  TI_CHANGE = 'ti_change',
  TI_DISABILITIES = 'ti_disabilities',
  TI_EXECUTIVE_TEAM = 'ti_executive_team',
  TI_GENDER = 'ti_gender',
  TI_LGBT = 'ti_lgbt',
  TI_MANAGERIAL_LEVEL = 'ti_managerial_level',
  TI_OPPORTUNITIES = 'ti_opportunities',
  TI_PAY_TYPE = 'ti_pay_type',
  TI_RACE = 'ti_race',
  TI_RESPONSIBILITY = 'ti_responsibility',
  TI_TENURE = 'ti_tenure',
  TI_UNIQUE = 'ti_unique',
  TI_WORK_POSTAL_CODE = 'ti_work_postal_code',
  TI_WORK_STATUS = 'ti_work_status',
  UNIT = 'unit',
  USER_ACTIVE = 'user_active',
  VALUE = 'value',
  WHY_LOCATION = 'why_location',
  WORK_EMAIL = 'work_email'
}

export type BenchmarkConfigInput = {
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
};

export type BenchmarkDepartmentType = {
   __typename?: 'BenchmarkDepartmentType';
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
  locs: Array<Scalars['String']>;
};

export type BenchmarkLevelOfCareType = {
   __typename?: 'BenchmarkLevelOfCareType';
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
  seniorLiving: Scalars['Boolean'];
  atHome: Scalars['Boolean'];
  multipleLocations: Scalars['Boolean'];
  /** Whether the level of care can be disabled */
  required: Scalars['Boolean'];
  /** Whether the level of care can be enabled */
  disabled: Scalars['Boolean'];
};

/**
 * A GraphQL type representing a generic benchmark, not necessarily backed
 * by a Django model.
 */
export type BenchmarkNode = {
   __typename?: 'BenchmarkNode';
  benchmarkType: BenchmarkTypeEnum;
  benchmarkUuid: Scalars['String'];
  lessThanMin: Scalars['Boolean'];
  minShowableResults: Scalars['Int'];
  name: Scalars['String'];
  group?: Maybe<Scalars['String']>;
};

/**
 * A GraphQL type representing a generic benchmark input, not necessarily backed
 * by a Django model.
 */
export type BenchmarkNodeInput = {
  benchmarkType?: Maybe<BenchmarkTypeEnum>;
  benchmarkUuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type BenchmarkShiftType = {
   __typename?: 'BenchmarkShiftType';
  checked: Scalars['Boolean'];
  code: Scalars['String'];
  name: Scalars['String'];
  customName?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum BenchmarkTypeEnum {
  INDUSTRY = 'INDUSTRY',
  INTERNAL = 'INTERNAL',
  PAST_SURVEY = 'PAST_SURVEY'
}

/** Delete a list of users, given their UUID. */
export type BulkDeleteUsers = {
   __typename?: 'BulkDeleteUsers';
  errors?: Maybe<Array<Scalars['String']>>;
};

/** Send a password reset email to a group of users based on list of uuid input */
export type BulkResetPassword = {
   __typename?: 'BulkResetPassword';
  errors?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};

export type BulkSetIsActive = {
   __typename?: 'BulkSetIsActive';
  errors?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};

export type BulkSetSurveyQuestion = {
   __typename?: 'BulkSetSurveyQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
  questions?: Maybe<Array<QuestionType>>;
};

/** Update a list of user's permissions. Currently limited to data access. */
export type BulkUpdateUserPermissions = {
   __typename?: 'BulkUpdateUserPermissions';
  errors?: Maybe<Array<Scalars['String']>>;
  users?: Maybe<Array<Maybe<UserNode>>>;
};

export type CertificationType = {
   __typename?: 'CertificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  organization: OrganizationType;
  survey: SurveyNode;
  /** Date when access to apply for certification ends. */
  accessEndDate: Scalars['DateTime'];
  /** Date when survey certified status begins. */
  certifiedStartDate?: Maybe<Scalars['DateTime']>;
  /** Date when survey certified status end. Defaults to a year from start date */
  certifiedEndDate?: Maybe<Scalars['DateTime']>;
  cultureBrief: CultureBriefType;
  passesScoreCriteria?: Maybe<Scalars['Boolean']>;
  passesResponseRateCriteria?: Maybe<Scalars['Boolean']>;
  bestWorkplacesRanking?: Maybe<Scalars['Int']>;
  locationCertifications: Array<LocationCertificationType>;
  status: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  organizationSlug?: Maybe<Scalars['String']>;
  badgeImageUrl?: Maybe<Scalars['String']>;
  badgeZipUrl?: Maybe<Scalars['String']>;
};

export type CodeLookup = {
   __typename?: 'CodeLookup';
  errors?: Maybe<Array<Scalars['String']>>;
  surveys?: Maybe<Array<PublicSurveyType>>;
};

export type ComparisonToolControls = {
   __typename?: 'ComparisonToolControls';
  statements: Array<FrozenQuestionType>;
  demographics: Array<InsightsDemographic>;
};

export type CompletionRateReportDataType = {
   __typename?: 'CompletionRateReportDataType';
  completionRate: CompletionRateReportType;
  rows: Array<CompletionRateRowType>;
};

export type CompletionRateReportType = {
   __typename?: 'CompletionRateReportType';
  started: Scalars['Int'];
  submitted: Scalars['Int'];
  totalFilter1: Scalars['Int'];
};

export type CompletionRateRowType = {
   __typename?: 'CompletionRateRowType';
  started: Scalars['Int'];
  submitted: Scalars['Int'];
  filterValue1: Scalars['String'];
  filterValue2: Scalars['String'];
};

export type CompletionRateType = {
   __typename?: 'CompletionRateType';
  started: Scalars['Int'];
  submitted: Scalars['Int'];
};

export type ContentObject = {
   __typename?: 'ContentObject';
  contentType: Scalars['String'];
  uuid: Scalars['String'];
};

export type ContentObjectInput = {
  contentType: Scalars['String'];
  uuid: Scalars['String'];
};

export type CultureBriefImageInput = {
  uuid?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  imageType: CultureBriefImageTypeEnum;
  caption: Scalars['String'];
};

export type CultureBriefImageType = {
   __typename?: 'CultureBriefImageType';
  uuid: Scalars['String'];
  imageType: CultureBriefImageTypeEnum;
  caption: Scalars['String'];
  url: Scalars['String'];
};

/** An enumeration. */
export enum CultureBriefImageTypeEnum {
  LOGO = 'LOGO',
  CEO = 'CEO',
  COMPANY = 'COMPANY'
}

export type CultureBriefType = {
   __typename?: 'CultureBriefType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  yearFounded?: Maybe<Scalars['DateTime']>;
  numberOfLocations?: Maybe<Scalars['Int']>;
  isSubsidiary?: Maybe<Scalars['Boolean']>;
  organizationType?: Maybe<Scalars['String']>;
  industrySubVertical?: Maybe<Scalars['String']>;
  mainTypeOfCare?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website: Scalars['String'];
  facebookUrl: Scalars['String'];
  twitterUrl: Scalars['String'];
  instagramUrl: Scalars['String'];
  linkedinUrl: Scalars['String'];
  indeedUrl: Scalars['String'];
  description: Scalars['String'];
  totalNumberOfEmployees?: Maybe<Scalars['Int']>;
  pastTotalNumberOfEmployees?: Maybe<Scalars['Int']>;
  totalApplicants?: Maybe<Scalars['Int']>;
  totalOpenRoles?: Maybe<Scalars['Int']>;
  recommendationsPercentage?: Maybe<Scalars['Int']>;
  averageNumberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEmployeesLeft?: Maybe<Scalars['Int']>;
  carbonEmissionsReductionPercentage?: Maybe<Scalars['Int']>;
  numberOfEmployeesEligibleForHealthInsurance?: Maybe<Scalars['Int']>;
  numberOfEmployeesWithHealthInsurance?: Maybe<Scalars['Int']>;
  employeeBenefits?: Maybe<Scalars['String']>;
  additionalEmployeeBenefits: Scalars['String'];
  percentageOfEmployeesWithBigWages?: Maybe<Scalars['Int']>;
  percentageOfImmigrantEmployees?: Maybe<Scalars['Int']>;
  totalBoardComposition?: Maybe<Scalars['Int']>;
  totalBoardWomen?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesRace?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesLgbt?: Maybe<Scalars['Int']>;
  totalCeoReportsComposition?: Maybe<Scalars['Int']>;
  totalCeoReportsWomen?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesRace?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesLgbt?: Maybe<Scalars['Int']>;
  hasDiversityEquity?: Maybe<Scalars['Boolean']>;
  diversityEquityDescription: Scalars['String'];
  reasonsToWorkForCompany: Scalars['String'];
  companyMission: Scalars['String'];
  careersPageLink: Scalars['String'];
  ceoFirstName: Scalars['String'];
  ceoLastName: Scalars['String'];
  ceoTitle: Scalars['String'];
  ceoPhoneNumber: Scalars['String'];
  ceoEmail: Scalars['String'];
  mainContactFirstName: Scalars['String'];
  mainContactLastName: Scalars['String'];
  mainContactTitle: Scalars['String'];
  mainContactPhoneNumber: Scalars['String'];
  mainContactEmail: Scalars['String'];
  marketingContactFirstName: Scalars['String'];
  marketingContactLastName: Scalars['String'];
  marketingContactTitle: Scalars['String'];
  marketingContactPhoneNumber: Scalars['String'];
  marketingContactEmail: Scalars['String'];
  certification?: Maybe<CertificationType>;
  images: Array<CultureBriefImageType>;
};

export type CultureChangeOverviewType = {
   __typename?: 'CultureChangeOverviewType';
  checkedOut?: Maybe<Array<Maybe<CultureChangeType>>>;
  highTrust?: Maybe<Array<Maybe<CultureChangeType>>>;
  highRisk?: Maybe<Array<Maybe<CultureChangeType>>>;
  wantsMore?: Maybe<Array<Maybe<CultureChangeType>>>;
};

export type CultureChangeType = {
   __typename?: 'CultureChangeType';
  demographicCode?: Maybe<Scalars['String']>;
  demographic?: Maybe<Scalars['String']>;
  valueCode?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  change?: Maybe<Scalars['Float']>;
};

export type CultureHighlightType = {
   __typename?: 'CultureHighlightType';
  demographicCode?: Maybe<Scalars['String']>;
  demographic?: Maybe<Scalars['String']>;
  valueCode?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

export type CultureOverviewType = {
   __typename?: 'CultureOverviewType';
  checkedOut?: Maybe<CultureType>;
  checkedOutBenchmark?: Maybe<Scalars['Float']>;
  highTrust?: Maybe<CultureType>;
  highTrustBenchmark?: Maybe<Scalars['Float']>;
  highRisk?: Maybe<CultureType>;
  highRiskBenchmark?: Maybe<Scalars['Float']>;
  wantsMore?: Maybe<CultureType>;
  wantsMoreBenchmark?: Maybe<Scalars['Float']>;
};

export type CultureType = {
   __typename?: 'CultureType';
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  top?: Maybe<Array<Maybe<CultureHighlightType>>>;
};

/** An enumeration. */
export enum DataTypeCode {
  AI_DEPT = 'ai_dept',
  AI_LOC = 'ai_loc',
  AI_LOCATION = 'ai_location',
  AI_SHIFT = 'ai_shift',
  APPLICATION_DATE = 'application_date',
  BUILDING = 'building',
  BUSINESS_SEGMENT = 'business_segment',
  CAMPUS = 'campus',
  CARE_TERM_ID = 'care_term_id',
  CLIENT_EXTERNAL_ID = 'client_external_id',
  CLIENT_NAME = 'client_name',
  DATE_OF_BIRTH = 'date_of_birth',
  DEPARTMENT_ID = 'department_id',
  DEPARTMENT_NAME = 'department_name',
  DISCHARGE_REASON = 'discharge_reason',
  DIVISION_ID = 'division_id',
  DIVISION_NAME = 'division_name',
  EMPLOYEE_LEFT_REASON = 'employee_left_reason',
  EMPLYOMENT_TERM_ID = 'emplyoment_term_id',
  FIRST_NAME = 'first_name',
  FLOOR = 'floor',
  FRANCHISE = 'franchise',
  GROUP = 'group',
  HEALTH = 'health',
  HIRE_DATE = 'hire_date',
  HOUSEHOLD_SIZE = 'household_size',
  IGNORE = 'ignore',
  IS_PARTICIPANT = 'is_participant',
  JOB_TITLE = 'job_title',
  LAST_NAME = 'last_name',
  LENGTH_OF_STAY = 'length_of_stay',
  LOCATION_ID = 'location_id',
  LONELINESS_GROUP = 'loneliness_group',
  MARITAL_STATUS = 'marital_status',
  MEDICAL_NEEDS = 'medical_needs',
  MOBILE_PHONE = 'mobile_phone',
  MOD_LENGTH_OF_STAY = 'mod_length_of_stay',
  MOVE_IN = 'move_in',
  MOVE_OUT = 'move_out',
  NEXT_PLACE = 'next_place',
  OWNER = 'owner',
  PARTICIPANT_TYPE = 'participant_type',
  PAYOR_TYPE = 'payor_type',
  PERSONAL_EMAIL = 'personal_email',
  R_BIRTH_YEAR = 'r_birth_year',
  R_DATE_OF_BIRTH = 'r_date_of_birth',
  R_FIRST_NAME = 'r_first_name',
  R_GENDER = 'r_gender',
  R_LAST_NAME = 'r_last_name',
  R_MOBILE_PHONE = 'r_mobile_phone',
  R_PERSONAL_EMAIL = 'r_personal_email',
  R_RELATIONSHIP = 'r_relationship',
  R_SAMPLE_RECORD_ID = 'r_sample_record_id',
  R_SURVEY_CODE = 'r_survey_code',
  REGION_ID = 'region_id',
  REGION_NAME = 'region_name',
  RESIDENT_GROUP = 'resident_group',
  RESIDENT_RESPONDENT_TYPE = 'resident_respondent_type',
  SAMPLE_RECORD_ID = 'sample_record_id',
  SENIOR_BIRTH_YEAR = 'senior_birth_year',
  SENIOR_DATE_OF_BIRTH = 'senior_date_of_birth',
  STATE = 'state',
  SUBTEAM = 'subteam',
  SUPERVISOR_ID = 'supervisor_id',
  SUPERVISOR_NAME = 'supervisor_name',
  SURVEY_CODE = 'survey_code',
  TEAM = 'team',
  TERMINATION_DATE = 'termination_date',
  TERMINATION_REASON = 'termination_reason',
  TERRITORY_CODE = 'territory_code',
  TI_BIRTH_YEAR = 'ti_birth_year',
  TI_DISABILITIES = 'ti_disabilities',
  TI_EXECUTIVE_TEAM = 'ti_executive_team',
  TI_GENDER = 'ti_gender',
  TI_LGBT = 'ti_lgbt',
  TI_MANAGERIAL_LEVEL = 'ti_managerial_level',
  TI_OPPORTUNITIES = 'ti_opportunities',
  TI_PAY_TYPE = 'ti_pay_type',
  TI_RACE = 'ti_race',
  TI_RESPONSIBILITY = 'ti_responsibility',
  TI_TENURE = 'ti_tenure',
  TI_WORK_POSTAL_CODE = 'ti_work_postal_code',
  TI_WORK_STATUS = 'ti_work_status',
  UNIT = 'unit',
  USER_ACTIVE = 'user_active',
  WORK_EMAIL = 'work_email'
}

export type DataTypeGroupsInputType = {
  dtCode: Scalars['String'];
  /** Limit to groups within the data type. e.g. specific locations within the filter type */
  groups?: Maybe<Array<Scalars['String']>>;
  isMultiselectCode?: Maybe<Scalars['Boolean']>;
};

export type DataTypeGroupsType = {
   __typename?: 'DataTypeGroupsType';
  dtCode: Scalars['String'];
  groups: Array<Scalars['String']>;
};

export type DataTypeInput = {
  code: BenchmarkCodeType;
  value: Scalars['String'];
};

export type DataTypeOptionType = {
   __typename?: 'DataTypeOptionType';
  code: BenchmarkCodeType;
  visibleName: Scalars['String'];
  standards?: Maybe<Array<StandardType>>;
  required: Scalars['Boolean'];
  /**
   * Data types that, when provided for a representative, must be the same as their client.
   * On the frontend, we clear those values and make sure to auto-populate on the backend during update.
   */
  mustSyncWithClient?: Maybe<Scalars['Boolean']>;
};

export type DataTypeValueType = {
   __typename?: 'DataTypeValueType';
  value: Scalars['String'];
  dtCode: BenchmarkCodeType;
};



/** Delete Statement of an Action Plan */
export type DeleteActionItem = {
   __typename?: 'DeleteActionItem';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type DeleteActionItemTask = {
   __typename?: 'DeleteActionItemTask';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type DeleteActionItemTaskComment = {
   __typename?: 'DeleteActionItemTaskComment';
  errors?: Maybe<Array<Scalars['String']>>;
  actionItemTask?: Maybe<ActionItemTaskNode>;
};

export type DeleteCultureBriefImages = {
   __typename?: 'DeleteCultureBriefImages';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteGroupsQuestionScoreNotification = {
   __typename?: 'DeleteGroupsQuestionScoreNotification';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteIntervalNotification = {
   __typename?: 'DeleteIntervalNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type DeleteParticipant = {
   __typename?: 'DeleteParticipant';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteQuestion = {
   __typename?: 'DeleteQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DeleteScheduledNotification = {
   __typename?: 'DeleteScheduledNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type DeleteSurvey = {
   __typename?: 'DeleteSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type DesignSectionType = {
   __typename?: 'DesignSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  languages: Array<LanguageType>;
};

export type DownloadNode = {
   __typename?: 'DownloadNode';
  title: Scalars['String'];
};

export type EligibleParticipantSurveyConfigInput = {
  participantTypeOptions: Scalars['String'];
  levelOfCare: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type EligibleParticipantSurveyConfigType = {
   __typename?: 'EligibleParticipantSurveyConfigType';
  uuid: Scalars['String'];
  participantTypeOptions: Scalars['String'];
  /** Whether config is enabled for this level of care */
  enabled: Scalars['Boolean'];
  levelOfCare: Scalars['String'];
};

export type EmailSupport = {
   __typename?: 'EmailSupport';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type EmployeeEligibilityRate = {
   __typename?: 'EmployeeEligibilityRate';
  certificationEligible: Scalars['Int'];
  listEligible: Scalars['Int'];
};

export type Errors = {
   __typename?: 'Errors';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/** An enumeration. */
export enum FileImportImportType {
  /** Participants */
  PARTICIPANTS = 'PARTICIPANTS',
  /** Users */
  USERS = 'USERS',
  /** Clients */
  CLIENTS = 'CLIENTS',
  /** Bulk Delete Participants */
  DELETE_PARTICIPANTS = 'DELETE_PARTICIPANTS',
  /** Clients And Representatives */
  CLIENTS_AND_REPS = 'CLIENTS_AND_REPS'
}

export type FileImportType = {
   __typename?: 'FileImportType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  organization: OrganizationType;
  survey?: Maybe<InsightsSurveyNode>;
  /** Inactive imports have been disassociated from a survey, but not deleted */
  active: Scalars['Boolean'];
  copiedFrom?: Maybe<FileImportType>;
  /** Optionally provide a name for future reference */
  name: Scalars['String'];
  importType: FileImportImportType;
  uploadedFile: Scalars['String'];
  columnErrors: Scalars['JSONString'];
  ingestErrors: Scalars['JSONString'];
  summary: ImportSummaryType;
  lastErrorMessage?: Maybe<Scalars['String']>;
  createdUsers: UserNodeConnection;
  fileimportSet: Array<FileImportType>;
  createdParticipants: Array<ParticipantType>;
  fileName?: Maybe<Scalars['String']>;
  errors: Array<ImportErrorType>;
  numColumns: Scalars['Int'];
  numRows: Scalars['Int'];
};


export type FileImportTypeCreatedUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum FilterProductTypeEnum {
  EMPLOYEE = 'EMPLOYEE',
  RESIDENT = 'RESIDENT',
  ALL = 'ALL'
}

/** An enumeration. */
export enum FilterProductTypePermissionEnum {
  EMPLOYEE = 'EMPLOYEE',
  RESIDENT = 'RESIDENT',
  ALL = 'ALL',
  NONE = 'NONE'
}

export type FilterTypePermissionInput = {
  uuid: Scalars['String'];
  accessToAll: Scalars['Boolean'];
  values: Array<Maybe<FilterValuePermissionInput>>;
};

/** An enumeration. */
export enum FilterTypeReportingTypeEnum {
  SELF_REPORTED = 'SELF_REPORTED',
  ADMIN_MAPPING = 'ADMIN_MAPPING'
}

export type FilterTypeResponse = {
   __typename?: 'FilterTypeResponse';
  name: Scalars['String'];
  rate: Scalars['Float'];
};

export type FilterTypeType = {
   __typename?: 'FilterTypeType';
  filterTypeUuid: Scalars['String'];
  index: Scalars['Int'];
  name: Scalars['String'];
  namePlural: Scalars['String'];
  dtCode: DataTypeCode;
  accessToAll?: Maybe<Scalars['Boolean']>;
  isLocation?: Maybe<Scalars['Boolean']>;
  filterValues: Array<FilterValueType>;
  insightsProductType: FilterProductTypePermissionEnum;
  accessControlProductType: FilterProductTypePermissionEnum;
  surveyProductType: FilterProductTypeEnum;
};

export type FilterValueInput = {
  uuid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<UsStatesEnum>;
  postalCode?: Maybe<Scalars['String']>;
  onlineReviewSites?: Maybe<Array<FilterValueReviewSiteInputType>>;
  website?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  hasEntranceFee?: Maybe<Scalars['Boolean']>;
  isRental?: Maybe<Scalars['Boolean']>;
  usNewsLocationId?: Maybe<Scalars['String']>;
  usNewsLocationName?: Maybe<Scalars['String']>;
  ncalIdNumber?: Maybe<Scalars['String']>;
  medicareProviderNumber?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
};

export type FilterValuePermissionInput = {
  uuid?: Maybe<Scalars['String']>;
};

export type FilterValueReviewSiteInputType = {
  externalId: Scalars['String'];
  reviewSiteName: OnlineReviewSiteEnum;
};

export type FilterValueReviewSiteType = {
   __typename?: 'FilterValueReviewSiteType';
  externalId: Scalars['String'];
  reviewSiteName: OnlineReviewSiteEnum;
};

export type FilterValuesSectionType = {
   __typename?: 'FilterValuesSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  mappedFilterValuesCount: Scalars['Int'];
  totalFilterValuesCount: Scalars['Int'];
};

export type FilterValueSummaryType = {
   __typename?: 'FilterValueSummaryType';
  filterTypeName?: Maybe<Scalars['String']>;
  filterValueNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FilterValueType = {
   __typename?: 'FilterValueType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  archived: Scalars['Boolean'];
  dataTypeValue?: Maybe<DataTypeValueType>;
  /** Standard value for data types based on standards (e.g. LevelOfCare) */
  standard?: Maybe<Scalars['String']>;
  addressLine1: Scalars['String'];
  addressLine2: Scalars['String'];
  city: Scalars['String'];
  state?: Maybe<UsStatesEnum>;
  postalCode: Scalars['String'];
  country: Scalars['String'];
  phoneNumber: Scalars['String'];
  website: Scalars['String'];
  /** Entrance fee Life Plan Community/CCRC */
  hasEntranceFee: Scalars['Boolean'];
  /** Rental Life Plan Community/CCRC */
  isRental: Scalars['Boolean'];
  logo: Scalars['String'];
  /** Shown to applicants on the screener as an explanation of 'why work here' */
  description: Scalars['String'];
  /** Short name to use in messaging. In Hiring product, defaults to Organization Name. Elsewhere, defaults to location.name */
  nickname: Scalars['String'];
  onlineReviewSites?: Maybe<Array<FilterValueReviewSiteType>>;
  usNewsLocationId: Scalars['String'];
  usNewsLocationName: Scalars['String'];
  ncalIdNumber: Scalars['String'];
  medicareProviderNumber: Scalars['String'];
  /** This field used being used as "security_phrase" when passing leads to Vicidial. */
  vicidialDid?: Maybe<Scalars['String']>;
  /** Employee Departments connected to Resident Service Areas */
  serviceAreas: Array<ServiceAreaType>;
  /**
   * The 'Personal Info Questions' section in open link surveys can be personalised
   * such that the options for a filter type question can only be a subset of all
   * the available filter value choices.
   */
  surveySet: Array<InsightsSurveyNode>;
  name: Scalars['String'];
  displayName: Scalars['String'];
  dtCode: BenchmarkCodeType;
  hasMissingRequiredValues: Scalars['Boolean'];
};

export type FrozenQuestionType = {
   __typename?: 'FrozenQuestionType';
  uuid: Scalars['String'];
  text: Scalars['String'];
  /** A version of question's text that replaces template code, such as {{dining_service}}, with readable labels. */
  textDisplay?: Maybe<Scalars['String']>;
  kind?: Maybe<QKind>;
  focus?: Maybe<QFocus>;
  code: Scalars['String'];
  residentResultsGroup?: Maybe<QResidentResultsGroup>;
  survey: SurveyNode;
  isOpenEnded: Scalars['Boolean'];
};

export type GenerateSignedUploadUrl = {
   __typename?: 'GenerateSignedUploadUrl';
  signedUrl?: Maybe<SignedUrl>;
};

export type GroupCountsType = {
   __typename?: 'GroupCountsType';
  label: Scalars['String'];
  count: Scalars['Int'];
};

export type GroupedScoresType = {
   __typename?: 'GroupedScoresType';
  dataType: DataTypeCode;
  scores: Array<OverallScoreType>;
  label?: Maybe<Scalars['String']>;
};

export type GroupedStatementScoresType = {
   __typename?: 'GroupedStatementScoresType';
  /** Data type the group is a part of. */
  dtCode: Scalars['String'];
  /** Name of individual groups within data type to limit calculations to. */
  group: Scalars['String'];
  statements: Array<StatementScoreType>;
};

export type GroupInput = {
  accessToSurveyProduct?: Maybe<Scalars['Boolean']>;
  canCreateSurveys?: Maybe<Scalars['Boolean']>;
  canAccessSurveySettings?: Maybe<Scalars['Boolean']>;
  canManageCertifications?: Maybe<Scalars['Boolean']>;
  canViewCertificationResults?: Maybe<Scalars['Boolean']>;
  residentAccessToSurveyProduct?: Maybe<Scalars['Boolean']>;
  residentCanCreateSurveys?: Maybe<Scalars['Boolean']>;
  residentCanAccessSurveySettings?: Maybe<Scalars['Boolean']>;
  canAccessLonelinessResults?: Maybe<Scalars['Boolean']>;
  canUseAnalytics?: Maybe<Scalars['Boolean']>;
  canInviteUsers?: Maybe<Scalars['Boolean']>;
  canResetUsersPasswords?: Maybe<Scalars['Boolean']>;
  canAccessDataStructure?: Maybe<Scalars['Boolean']>;
  canAccessGeneralSettings?: Maybe<Scalars['Boolean']>;
  canUseActionPlans?: Maybe<Scalars['Boolean']>;
  canManageActionPlans?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
  uuid?: Maybe<Scalars['String']>;
  allSurveys?: Maybe<Scalars['Boolean']>;
  surveys?: Maybe<Array<Maybe<Scalars['String']>>>;
  insightsModules?: Maybe<Array<InsightsModulesEnum>>;
  residentInsightsModules?: Maybe<Array<InsightsModulesEnum>>;
};

export type GroupNode = {
   __typename?: 'GroupNode';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  organization: OrganizationType;
  createdBy?: Maybe<UserNode>;
  /** Is the organization's admin group? One per org, should contain all valid permissions, and not be allowed deleted. */
  isAdmin: Scalars['Boolean'];
  groupssurveyresponsenotificationSet: Array<GroupsSurveyResponseNotificationType>;
  groupsaveragescoresurveyresponsenotificationSet: Array<GroupsSurveyAverageScoreNotificationType>;
  groupsquestionscoresurveyresponsenotificationSet: Array<GroupsSurveyQuestionScoreNotificationType>;
  accessToSurveyProduct: Scalars['Boolean'];
  canCreateSurveys: Scalars['Boolean'];
  canAccessSurveySettings: Scalars['Boolean'];
  canManageCertifications: Scalars['Boolean'];
  canViewCertificationResults: Scalars['Boolean'];
  residentAccessToSurveyProduct: Scalars['Boolean'];
  residentCanCreateSurveys: Scalars['Boolean'];
  residentCanAccessSurveySettings: Scalars['Boolean'];
  canAccessLonelinessResults: Scalars['Boolean'];
  canUseAnalytics: Scalars['Boolean'];
  canInviteUsers: Scalars['Boolean'];
  canResetUsersPasswords: Scalars['Boolean'];
  canAccessDataStructure: Scalars['Boolean'];
  canAccessGeneralSettings: Scalars['Boolean'];
  canUseActionPlans: Scalars['Boolean'];
  canManageActionPlans: Scalars['Boolean'];
  users: Array<UserNode>;
  insightsModules: Array<InsightsModulesEnum>;
  residentInsightsModules: Array<InsightsModulesEnum>;
  surveys: Array<SurveyNode>;
  allSurveys: Scalars['Boolean'];
};

export type GroupsSurveyAverageScoreNotificationType = {
   __typename?: 'GroupsSurveyAverageScoreNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  groups: Array<GroupNode>;
  survey: InsightsSurveyNode;
  scoreType: SurveyScoreNotificationTypeEnum;
  frequency: SurveyScoreNotificationFrequencyEnum;
};

export type GroupsSurveyQuestionScoreNotificationType = {
   __typename?: 'GroupsSurveyQuestionScoreNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  groups: Array<GroupNode>;
  survey: InsightsSurveyNode;
  notificationName: Scalars['String'];
  scoreType: SurveyScoreNotificationTypeEnum;
  frequency: SurveyScoreNotificationFrequencyEnum;
  minScore: Scalars['Int'];
  maxScore: Scalars['Int'];
  statement: QuestionType;
  openEndedQuestion?: Maybe<QuestionType>;
};

export type GroupsSurveyResponseNotificationType = {
   __typename?: 'GroupsSurveyResponseNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  enabled: Scalars['Boolean'];
  groups: Array<GroupNode>;
  survey: InsightsSurveyNode;
  notificationType: SurveyResponseNotificationTypeEnum;
};

export type GroupSurveyAverageScoreNotificationInput = {
  scoreType: SurveyScoreNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  frequency?: Maybe<SurveyScoreNotificationFrequencyEnum>;
  groupUuids: Array<Scalars['String']>;
};

export type GroupSurveyQuestionScoreNotificationInput = {
  notificationUuid?: Maybe<Scalars['String']>;
  notificationName: Scalars['String'];
  scoreType: SurveyScoreNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
  groupUuids: Array<Scalars['String']>;
  minScore: Scalars['Int'];
  maxScore: Scalars['Int'];
  statementUuid: Scalars['String'];
  openEndedQuestionUuid?: Maybe<Scalars['String']>;
};

export type GroupSurveyResponseNotificationInput = {
  notificationType: SurveyResponseNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  groupUuids: Array<Maybe<Scalars['String']>>;
};

export type HeatmapBenchmarkScores = {
   __typename?: 'HeatmapBenchmarkScores';
  name: Scalars['String'];
  scores: Array<Maybe<Scalars['Float']>>;
};

export type HeatmapCellType = {
   __typename?: 'HeatmapCellType';
  score: Scalars['Float'];
};

export type HeatmapControls = {
   __typename?: 'HeatmapControls';
  demographics: Array<InsightsDemographic>;
};

export type HeatmapType = {
   __typename?: 'HeatmapType';
  statements?: Maybe<Array<Maybe<FrozenQuestionType>>>;
  categories: Array<Maybe<Scalars['String']>>;
  cells: Array<Maybe<Array<Maybe<HeatmapCellType>>>>;
};

/** An enumeration. */
export enum ImpactScoreStrategyEnum {
  POSITIVE_SCORE = 'POSITIVE_SCORE',
  NPS_SCORE = 'NPS_SCORE',
  NUM_RESPONSES = 'NUM_RESPONSES'
}

/** An enumeration. */
export enum ImportContactTypesEnum {
  EMPLOYEES = 'EMPLOYEES',
  COMBINED_CONTACTS = 'COMBINED_CONTACTS'
}

/** An enumeration. */
export enum ImportErrorsEnum {
  BLANK_COLUMN = 'BLANK_COLUMN',
  DUPLICATE_COLUMN = 'DUPLICATE_COLUMN',
  DUPLICATE_VALUE = 'DUPLICATE_VALUE',
  EXISTING_DUPLICATE_VALUE = 'EXISTING_DUPLICATE_VALUE',
  FAILED_COLUMN_MAPPING = 'FAILED_COLUMN_MAPPING',
  INVALID_COLUMN = 'INVALID_COLUMN',
  INVALID_EXCEL_FORMAT = 'INVALID_EXCEL_FORMAT',
  INVALID_LEVEL_OF_CARE = 'INVALID_LEVEL_OF_CARE',
  INVALID_STANDARDS_MAPPING = 'INVALID_STANDARDS_MAPPING',
  INVALID_VALUE = 'INVALID_VALUE',
  MISSING_REQUIRED_COLUMN = 'MISSING_REQUIRED_COLUMN',
  MISSING_REQUIRED_VALUE = 'MISSING_REQUIRED_VALUE'
}

export type ImportErrorType = {
   __typename?: 'ImportErrorType';
  code?: Maybe<ImportErrorsEnum>;
  dtCode?: Maybe<Scalars['String']>;
  dtName?: Maybe<Scalars['String']>;
  cell?: Maybe<Scalars['String']>;
};

export type ImportSummaryType = {
   __typename?: 'ImportSummaryType';
  processed?: Maybe<Scalars['Boolean']>;
  added?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Int']>;
  filters?: Maybe<Array<Maybe<FilterValueSummaryType>>>;
};

/** An enumeration. */
export enum ImportTypesEnum {
  PARTICIPANTS = 'PARTICIPANTS',
  USERS = 'USERS',
  CLIENTS = 'CLIENTS',
  BULK_DELETE_PARTICIPANTS = 'BULK_DELETE_PARTICIPANTS',
  CLIENTS_AND_REPRESENTATIVES = 'CLIENTS_AND_REPRESENTATIVES'
}

export type ImprovedGroupType = {
   __typename?: 'ImprovedGroupType';
  score: Scalars['Int'];
  name: Scalars['String'];
};

export type ImprovementScoreType = {
   __typename?: 'ImprovementScoreType';
  improvementScore: Scalars['Float'];
  improvedGroups?: Maybe<Array<ImprovedGroupType>>;
};

export type IndividualResponseType = {
   __typename?: 'IndividualResponseType';
  answer?: Maybe<Scalars['String']>;
  /** Provided for multiselect questions. */
  answers?: Maybe<Array<Scalars['String']>>;
  questionCode: Scalars['String'];
};

export type IndividualResultType = {
   __typename?: 'IndividualResultType';
  participantUuid: Scalars['String'];
  locationName: Scalars['String'];
  levelOfCare?: Maybe<Scalars['String']>;
  startedTimestamp: Scalars['DateTime'];
  submittedTimestamp?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  responses: Array<IndividualResponseType>;
  /**
   * Total number of results from the query. Kept at the individual
   *         result level to allow for simpler pagination.
   */
  totalResults: Scalars['Int'];
  questions: Array<ParticipantQuestion>;
  source?: Maybe<ParticipantSourceEnum>;
  /** The datetime at which the participant was called */
  callTime?: Maybe<Scalars['DateTime']>;
  /** The duration fo the call in seconds */
  callLength?: Maybe<Scalars['Int']>;
  lastSentNotificationTimestamp?: Maybe<Scalars['DateTime']>;
  participantType?: Maybe<ParticipantTypeEnum>;
  dischargeDate?: Maybe<Scalars['DateTime']>;
  /** For representatives, the name of the client the survey is taken on behalf of. */
  clientName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
};

export type IngestParticipantsUpload = {
   __typename?: 'IngestParticipantsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type InsightsComment = {
   __typename?: 'InsightsComment';
  text: Scalars['String'];
  score?: Maybe<Scalars['Int']>;
  author?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['DateTime']>;
  filterValues?: Maybe<Array<Scalars['String']>>;
};

export type InsightsComments = {
   __typename?: 'InsightsComments';
  comments: Array<InsightsComment>;
  question?: Maybe<FrozenQuestionType>;
  totalComments: Scalars['Int'];
};

export type InsightsCustomSurveyAnswer = {
   __typename?: 'InsightsCustomSurveyAnswer';
  value: Scalars['String'];
  count: Scalars['Int'];
  text: Scalars['String'];
  score: Scalars['Float'];
};

export type InsightsCustomSurveyQuestion = {
   __typename?: 'InsightsCustomSurveyQuestion';
  question: FrozenQuestionType;
  answers: Array<InsightsCustomSurveyAnswer>;
  selectedFiltersAnswers: Array<InsightsCustomSurveyAnswer>;
};

export type InsightsDemographic = {
   __typename?: 'InsightsDemographic';
  code: Scalars['String'];
  text: Scalars['String'];
  pluralText: Scalars['String'];
};

/** An enumeration. */
export enum InsightsModulesEnum {
  SNAPSHOTS = 'SNAPSHOTS',
  STATEMENTS = 'STATEMENTS',
  COMMENTS = 'COMMENTS',
  COMPARISONS = 'COMPARISONS',
  HEATMAP = 'HEATMAP',
  ACTION_PLANS = 'ACTION_PLANS',
  TESTIMONIALS = 'TESTIMONIALS',
  INDIVIDUAL_RESULTS = 'INDIVIDUAL_RESULTS'
}

export type InsightsSurveyNode = {
   __typename?: 'InsightsSurveyNode';
  uuid: Scalars['String'];
  responseRate: ResponseRateType;
  completionRate: CompletionRateType;
  overallIndexScore?: Maybe<OverallScoreType>;
};


export type InsightsSurveyNodeResponseRateArgs = {
  byClient?: Maybe<Scalars['Boolean']>;
  surveyResponseRateTypes?: Maybe<Array<SurveysResponseRateTypeInput>>;
};

export type IntervalNotificationInput = {
  uuid?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['Boolean']>;
  personalEmail?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  smsOptional?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTextOptional1?: Maybe<Scalars['String']>;
  emailTextOptional2?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum IntervalNotificationNotificationType {
  /** Starting */
  SURVEY_STARTING = 'SURVEY_STARTING',
  /** Reminder */
  REMINDER = 'REMINDER',
  /** Ending */
  SURVEY_ENDING = 'SURVEY_ENDING',
  /** Other */
  OTHER = 'OTHER'
}

export type IntervalNotificationType = {
   __typename?: 'IntervalNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  /** The part of the SMS that can be customized. */
  smsOptional: Scalars['String'];
  /** The required part of the SMS. */
  smsRequired: Scalars['String'];
  /** This is NOT customizable. */
  emailSubject: Scalars['String'];
  /** The first part of the email that can be customized. */
  emailTextOptional1: Scalars['String'];
  /** The required part of the email. */
  emailTextRequired: Scalars['String'];
  /** The second part of the email that can be customized. */
  emailTextOptional2: Scalars['String'];
  notificationType: IntervalNotificationNotificationType;
  sms: Scalars['Boolean'];
  personalEmail: Scalars['Boolean'];
  workEmail: Scalars['Boolean'];
  /** Is this notification set as active/usable? */
  active: Scalars['Boolean'];
  /** How many days after participant was added, should the notification go out? */
  days: Scalars['Int'];
  survey: InsightsSurveyNode;
  errors?: Maybe<Scalars['String']>;
  firstSentTimestamp?: Maybe<Scalars['DateTime']>;
};


export type LanguageType = {
   __typename?: 'LanguageType';
  /** For example "es" for Spanish */
  code: Scalars['String'];
  /** For example "Spanish" */
  name: Scalars['String'];
  /** For example "Español" */
  nameTranslated: Scalars['String'];
};

export type LastSurveyData = {
   __typename?: 'LastSurveyData';
  survey: SurveyNode;
  score: OverallScoreType;
};

export type LocationCertificationType = {
   __typename?: 'LocationCertificationType';
  name: Scalars['String'];
  isCertified: Scalars['Boolean'];
  locationUuid: Scalars['String'];
  uuid: Scalars['String'];
  badgeImageUrl?: Maybe<Scalars['String']>;
};

/** Authentication mutation, deletes the session. */
export type LogoutUser = {
   __typename?: 'LogoutUser';
  success?: Maybe<Scalars['Boolean']>;
};

export type LonelinessScoresType = {
   __typename?: 'LonelinessScoresType';
  numLonely: Scalars['Int'];
  numSomewhatLonely: Scalars['Int'];
  numNotLonely: Scalars['Int'];
};

export type Mutation = {
   __typename?: 'Mutation';
  /** Log the user out. */
  logoutUser?: Maybe<LogoutUser>;
  /** Send a password reset link to a group of users, given by their UUID. */
  bulkResetPassword?: Maybe<BulkResetPassword>;
  /** Delete a list of users, given their UUID. */
  bulkDeleteUsers?: Maybe<BulkDeleteUsers>;
  /** Activate/Deactivate user. */
  toggleIsActive?: Maybe<ToggleIsActive>;
  /** Bulk set active status for users */
  bulkSetIsActive?: Maybe<BulkSetIsActive>;
  /** If a uuid is given, it updates the user. Else, it sends an invitation email to the given email. */
  updateUser?: Maybe<UpdateUser>;
  /** Update a list of user permissions - limited to filter type permissions */
  bulkUpdateUserPermissions?: Maybe<BulkUpdateUserPermissions>;
  /** Add or remove an action plan that the user follows */
  toggleFollowActionPlan?: Maybe<ToggleFollowActionPlan>;
  /** Add or remove a survey from the user's favorites list. */
  toggleFavoriteSurvey?: Maybe<ToggleFavoriteSurvey>;
  /** Send AI support an email requesting info for a user. */
  emailSupport?: Maybe<EmailSupport>;
  /** Create user app message or mark existing message as seen for the current user. */
  updateUserAppMessage?: Maybe<UpdateUserAppMessage>;
  updateTranslations?: Maybe<UpdateTranslations>;
  /** Mutation class to create or update an existing Survey instance */
  updateSurvey?: Maybe<UpdateSurvey>;
  /** Mutation class to update the Survey status. Basically called when launching a survey or unscheduling it */
  updateSurveyStatus?: Maybe<UpdateSurveyStatus>;
  /** Mutation class to create or update SurveyText instances (welcome message, welcome header etc) */
  updateWelcomeMessages?: Maybe<UpdateWelcomeMessages>;
  generateUploadUrl?: Maybe<GenerateSignedUploadUrl>;
  deleteSurvey?: Maybe<DeleteSurvey>;
  updateCustomQuestion?: Maybe<UpdateCustomQuestion>;
  updatePersonalInfoQuestion?: Maybe<UpdatePersonalInfoQuestion>;
  deleteQuestion?: Maybe<DeleteQuestion>;
  bulkSetSurveyQuestion?: Maybe<BulkSetSurveyQuestion>;
  updateEligibleParticipantSurveyConfigs?: Maybe<UpdateEligibleParticipantSurveyConfigs>;
  codeLookup?: Maybe<CodeLookup>;
  saveResponses?: Maybe<SaveResponses>;
  updateResidentParticipant?: Maybe<UpdateResidentParticipant>;
  /** Save responses to the Personal Info questions. */
  savePersonalInfoResponses?: Maybe<SavePersonalInfoResponses>;
  updateParticipant?: Maybe<UpdateParticipant>;
  deleteParticipant?: Maybe<DeleteParticipant>;
  addParticipantsUpload?: Maybe<AddParticipantsUpload>;
  removeParticipantsUpload?: Maybe<RemoveParticipantsUpload>;
  ingestParticipantsUpload?: Maybe<IngestParticipantsUpload>;
  /** Updates Organization basic info */
  updateOrganization?: Maybe<UpdateOrganization>;
  /** Updates Organization info related to surveys */
  updateOrganizationSurveyPortal?: Maybe<UpdateOrganizationSurveyPortal>;
  /** Updates services areas on an organization */
  updateOrganizationServiceAreas?: Maybe<UpdateOrganizationServiceAreas>;
  /** Creates a new filter value or updates an existing one */
  updateFilterValue?: Maybe<UpdateFilterValue>;
  /** Toggles archived state of a filter value */
  updateFilterValueArchived?: Maybe<UpdateFilterValueArchived>;
  /** Uploads a file of contacts for an organization */
  addContactsUpload?: Maybe<AddContactsUpload>;
  /** Update or create a notification */
  updateScheduledNotification?: Maybe<UpdateScheduledNotification>;
  /** Delete a notification */
  deleteScheduledNotification?: Maybe<DeleteScheduledNotification>;
  /** Send a sample test email */
  sendScheduledTestEmail?: Maybe<SendScheduledTestEmail>;
  /** Send a sample test SMS */
  sendScheduledTestSms?: Maybe<SendScheduledTestSms>;
  /** Reset the notification to the default version */
  resetScheduledNotification?: Maybe<ResetScheduledNotification>;
  /** Update or create a notification */
  updateIntervalNotification?: Maybe<UpdateIntervalNotification>;
  /** Delete a notification */
  deleteIntervalNotification?: Maybe<DeleteIntervalNotification>;
  /** Send a sample test email */
  sendIntervalTestEmail?: Maybe<SendIntervalTestEmail>;
  /** Send a sample test SMS */
  sendIntervalTestSms?: Maybe<SendIntervalTestSms>;
  /** Reset the notification to the default version */
  resetIntervalNotification?: Maybe<ResetIntervalNotification>;
  /** Update or create survey response notifications for groups */
  updateGroupsSurveyResponseNotifications?: Maybe<UpdateGroupsSurveyResponseNotifications>;
  /** Update or create survey average score notifications for groups */
  updateGroupsSurveyAverageScoreNotifications?: Maybe<UpdateGroupsAverageScoreNotifications>;
  /** Update or create survey question score notifications for groups */
  updateGroupsSurveyQuestionScoreNotifications?: Maybe<UpdateGroupsQuestionScoreNotifications>;
  /** Delete a question score notification */
  deleteGroupsSurveyQuestionScoreNotification?: Maybe<DeleteGroupsQuestionScoreNotification>;
  /** Toggle user has survey response notifications */
  updateUserHasSurveyResponseNotifications?: Maybe<UpdateUserHasSurveyResponseNotifications>;
  /** Update or create survey response notifications for a user */
  updateUserSurveyResponseNotifications?: Maybe<UpdateUserSurveyResponseNotifications>;
  /** Update or create survey average score notifications for a user */
  updateUserAverageScoreNotifications?: Maybe<UpdateUserAverageScoreNotifications>;
  /** Update or create survey question score notifications for a user */
  updateUserQuestionScoreNotifications?: Maybe<UpdateUserQuestionScoreNotifications>;
  updateReport?: Maybe<UpdateReport>;
  shareScreenshot?: Maybe<ShareScreenshot>;
  updateTakeActionSteps?: Maybe<UpdateTakeActionStepsMutation>;
  /** Create or update a group. */
  updateGroup?: Maybe<UpdateGroup>;
  /** Update which groups can access the given survey */
  updateGroupsAccessToSurvey?: Maybe<UpdateGroupsAccessToSurvey>;
  updateCultureBrief?: Maybe<UpdateCultureBriefPayload>;
  addCultureBriefImages?: Maybe<AddCultureBriefImages>;
  deleteCultureBriefImages?: Maybe<DeleteCultureBriefImages>;
  updateCultureBriefImages?: Maybe<UpdateCultureBriefImages>;
  shareMarketingToolkit?: Maybe<ShareMarketingToolkit>;
  /** Set the employee/resident survey on the Action Plan */
  updateActionPlanSurvey?: Maybe<UpdateActionPlanSurvey>;
  /** Delete Statement of an Action Plan */
  deleteActionItem?: Maybe<DeleteActionItem>;
  /** Add a new Action Plan Item (represented by a statement actually) */
  addActionPlanStatements?: Maybe<AddActionPlanStatements>;
  addActionPlanCustomStatement?: Maybe<AddActionPlanCustomStatement>;
  updateActionItemTask?: Maybe<UpdateActionItemTask>;
  deleteActionItemTask?: Maybe<DeleteActionItemTask>;
  updateActionItemTaskComment?: Maybe<UpdateActionItemTaskComment>;
  deleteActionItemTaskComment?: Maybe<DeleteActionItemTaskComment>;
};


export type MutationBulkResetPasswordArgs = {
  uuidList?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationBulkDeleteUsersArgs = {
  userUuids: Array<Scalars['String']>;
};


export type MutationToggleIsActiveArgs = {
  uuid: Scalars['String'];
};


export type MutationBulkSetIsActiveArgs = {
  isActive: Scalars['Boolean'];
  uuidList: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateUserArgs = {
  email?: Maybe<Scalars['String']>;
  filterTypes: Array<FilterTypePermissionInput>;
  firstName?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationBulkUpdateUserPermissionsArgs = {
  filterTypes: Array<FilterTypePermissionInput>;
  uuidList: Array<Maybe<Scalars['String']>>;
};


export type MutationToggleFollowActionPlanArgs = {
  planUuid?: Maybe<Scalars['String']>;
};


export type MutationToggleFavoriteSurveyArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserAppMessageArgs = {
  contentObjects?: Maybe<Array<Maybe<ContentObjectInput>>>;
  kind: Scalars['String'];
};


export type MutationUpdateTranslationsArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<Maybe<TranslationInput>>>;
};


export type MutationUpdateSurveyArgs = {
  survey?: Maybe<SurveyInput>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateSurveyStatusArgs = {
  status?: Maybe<SurveyStatusEnum>;
  surveyUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateWelcomeMessagesArgs = {
  surveyUuid: Scalars['String'];
  welcomeMessages: Array<SurveyTextInput>;
};


export type MutationGenerateUploadUrlArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type MutationDeleteSurveyArgs = {
  uuid: Scalars['String'];
};


export type MutationUpdateCustomQuestionArgs = {
  questionInput: QuestionInput;
  surveyUuid: Scalars['String'];
};


export type MutationUpdatePersonalInfoQuestionArgs = {
  questionInput: PersonalInfoQuestionInput;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteQuestionArgs = {
  questionUuid: Scalars['String'];
  surveyUuid: Scalars['String'];
};


export type MutationBulkSetSurveyQuestionArgs = {
  selectedQuestionUuids: Array<Scalars['String']>;
  surveyUuid: Scalars['String'];
  unselectedQuestionUuids: Array<Scalars['String']>;
};


export type MutationUpdateEligibleParticipantSurveyConfigsArgs = {
  configs: Array<EligibleParticipantSurveyConfigInput>;
  surveyUuid: Scalars['String'];
};


export type MutationCodeLookupArgs = {
  code: Scalars['String'];
  organizationUuid: Scalars['String'];
  surveyProductType: SurveyProductTypeEnum;
};


export type MutationSaveResponsesArgs = {
  fingerprint?: Maybe<Scalars['String']>;
  participantId: Scalars['String'];
  responses?: Maybe<Array<Maybe<QuestionResponse>>>;
  selectedLanguage?: Maybe<Scalars['String']>;
  source?: Maybe<ParticipantSourceTypeInput>;
  submitted?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateResidentParticipantArgs = {
  fingerprint?: Maybe<Scalars['String']>;
  participantId: Scalars['String'];
  questionCode?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  source?: Maybe<ParticipantSourceTypeInput>;
  submitted?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};


export type MutationSavePersonalInfoResponsesArgs = {
  clientFirstName?: Maybe<Scalars['String']>;
  clientLastName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  responses: Array<QuestionResponse>;
  selectedLanguage?: Maybe<Scalars['String']>;
  surveyHashId: Scalars['String'];
};


export type MutationUpdateParticipantArgs = {
  participant: ParticipantInput;
  participantUuid?: Maybe<Scalars['String']>;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteParticipantArgs = {
  participantUuid: Scalars['String'];
};


export type MutationAddParticipantsUploadArgs = {
  importType: ImportTypesEnum;
  objectPath: Scalars['String'];
  surveyUuid: Scalars['String'];
};


export type MutationRemoveParticipantsUploadArgs = {
  importType: ImportTypesEnum;
  surveyUuid: Scalars['String'];
};


export type MutationIngestParticipantsUploadArgs = {
  importType: ImportTypesEnum;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateOrganizationArgs = {
  organization?: Maybe<OrganizationInput>;
};


export type MutationUpdateOrganizationSurveyPortalArgs = {
  organization?: Maybe<OrganizationSurveyPortalInput>;
};


export type MutationUpdateOrganizationServiceAreasArgs = {
  serviceAreas: Array<ServiceAreaInputType>;
};


export type MutationUpdateFilterValueArgs = {
  filterTypeUuid: Scalars['String'];
  filterValue: FilterValueInput;
};


export type MutationUpdateFilterValueArchivedArgs = {
  uuid: Scalars['String'];
};


export type MutationAddContactsUploadArgs = {
  importType: ImportContactTypesEnum;
  objectPath: Scalars['String'];
};


export type MutationUpdateScheduledNotificationArgs = {
  notificationInput: ScheduledNotificationInput;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteScheduledNotificationArgs = {
  notificationUuid: Scalars['String'];
};


export type MutationSendScheduledTestEmailArgs = {
  emailAddress: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationSendScheduledTestSmsArgs = {
  notificationUuid: Scalars['String'];
  smsNumber: Scalars['String'];
};


export type MutationResetScheduledNotificationArgs = {
  emailOrSms: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationUpdateIntervalNotificationArgs = {
  notificationInput: IntervalNotificationInput;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteIntervalNotificationArgs = {
  notificationUuid: Scalars['String'];
};


export type MutationSendIntervalTestEmailArgs = {
  emailAddress: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationSendIntervalTestSmsArgs = {
  notificationUuid: Scalars['String'];
  smsNumber: Scalars['String'];
};


export type MutationResetIntervalNotificationArgs = {
  emailOrSms: Scalars['String'];
  notificationUuid: Scalars['String'];
};


export type MutationUpdateGroupsSurveyResponseNotificationsArgs = {
  groupsNotificationsInput: Array<GroupSurveyResponseNotificationInput>;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateGroupsSurveyAverageScoreNotificationsArgs = {
  groupsNotificationsInput: Array<GroupSurveyAverageScoreNotificationInput>;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateGroupsSurveyQuestionScoreNotificationsArgs = {
  groupsNotificationsInput: Array<GroupSurveyQuestionScoreNotificationInput>;
  surveyUuid: Scalars['String'];
};


export type MutationDeleteGroupsSurveyQuestionScoreNotificationArgs = {
  notificationUuid: Scalars['String'];
};


export type MutationUpdateUserHasSurveyResponseNotificationsArgs = {
  hasSurveyResponseNotifications: Scalars['Boolean'];
};


export type MutationUpdateUserSurveyResponseNotificationsArgs = {
  userNotificationsInput: Array<UserSurveyResponseNotificationInput>;
};


export type MutationUpdateUserAverageScoreNotificationsArgs = {
  userNotificationsInput: Array<UserAverageScoreNotificationsInput>;
};


export type MutationUpdateUserQuestionScoreNotificationsArgs = {
  userNotificationsInput: Array<UserQuestionScoreNotificationsInput>;
};


export type MutationUpdateReportArgs = {
  password?: Maybe<Scalars['String']>;
  surveyUuid: Scalars['String'];
};


export type MutationShareScreenshotArgs = {
  currentUrl: Scalars['String'];
  email: Scalars['String'];
  image: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};


export type MutationUpdateTakeActionStepsArgs = {
  step: TakeActionStepsEnum;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  groupInput?: Maybe<GroupInput>;
};


export type MutationUpdateGroupsAccessToSurveyArgs = {
  groupsUuidsWithSurveyAccess: Array<Scalars['String']>;
  surveyUuid: Scalars['String'];
};


export type MutationUpdateCultureBriefArgs = {
  input: UpdateCultureBriefInput;
};


export type MutationAddCultureBriefImagesArgs = {
  cultureBriefUuid: Scalars['String'];
  imagesData: Array<Maybe<CultureBriefImageInput>>;
};


export type MutationDeleteCultureBriefImagesArgs = {
  cultureBriefUuid: Scalars['String'];
  uuids: Array<Scalars['String']>;
};


export type MutationUpdateCultureBriefImagesArgs = {
  cultureBriefUuid: Scalars['String'];
  imagesData: Array<Maybe<CultureBriefImageInput>>;
};


export type MutationShareMarketingToolkitArgs = {
  email: Scalars['String'];
  includeBadgeAssets: Scalars['Boolean'];
  locationUuid?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
};


export type MutationUpdateActionPlanSurveyArgs = {
  actionPlanInput?: Maybe<ActionPlanSurveyInput>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationDeleteActionItemArgs = {
  actionItemUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationAddActionPlanStatementsArgs = {
  includeDefaultActionItems: Scalars['Boolean'];
  statementUuids: Array<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationAddActionPlanCustomStatementArgs = {
  customStatement: Scalars['String'];
  customStatementProductType?: Maybe<SurveyProductTypeEnum>;
  tasks: Array<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateActionItemTaskArgs = {
  actionItemUuid: Scalars['String'];
  task: ActionItemTaskInput;
  taskUuid?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationDeleteActionItemTaskArgs = {
  taskId: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationUpdateActionItemTaskCommentArgs = {
  actionItemTaskUuid: Scalars['String'];
  commentInput?: Maybe<ActionItemTaskCommentInput>;
  userTimezone?: Maybe<Scalars['String']>;
  userUuid?: Maybe<Scalars['String']>;
};


export type MutationDeleteActionItemTaskCommentArgs = {
  actionItemTaskCommentUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type NotificationsSectionType = {
   __typename?: 'NotificationsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  total: Scalars['Int'];
  notifications: Array<NotificationType>;
};

export type NotificationType = {
   __typename?: 'NotificationType';
  uuid: Scalars['String'];
  workEmail: Scalars['Boolean'];
  personalEmail: Scalars['Boolean'];
  sms: Scalars['Boolean'];
  when?: Maybe<Scalars['DateTime']>;
  days?: Maybe<Scalars['Int']>;
  sentTimestamp?: Maybe<Scalars['DateTime']>;
};

export type NpsGroupScoresType = {
   __typename?: 'NpsGroupScoresType';
  promoters: Scalars['Float'];
  passives: Scalars['Float'];
  detractors: Scalars['Float'];
  label?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  totalResponses: Scalars['Int'];
};

/** An enumeration. */
export enum NpsGroupsEnum {
  PROMOTERS = 'PROMOTERS',
  PASSIVES = 'PASSIVES',
  DETRACTORS = 'DETRACTORS'
}

/** Options for online review sites customers can link to from a survey. */
export enum OnlineReviewSiteEnum {
  GOOGLE = 'GOOGLE'
}

export type OrganizationInput = {
  name: Scalars['String'];
  seniorLiving: Scalars['Boolean'];
  atHome: Scalars['Boolean'];
  isCcrc: Scalars['Boolean'];
  multipleLocations: Scalars['Boolean'];
  benchmarkLevelsOfCare: Array<BenchmarkConfigInput>;
  benchmarkDepartments: Array<BenchmarkConfigInput>;
  benchmarkShifts: Array<BenchmarkConfigInput>;
};

export type OrganizationSurveyPortalInput = {
  slug: Scalars['String'];
  surveyCodeName?: Maybe<Scalars['String']>;
  surveyCodeHelpText?: Maybe<Scalars['String']>;
  residentSurveyCodeName?: Maybe<Scalars['String']>;
  residentSurveyCodeHelpText?: Maybe<Scalars['String']>;
};

export type OrganizationType = {
   __typename?: 'OrganizationType';
  uuid: Scalars['String'];
  /**
   * Customers can use different names for their survey_code such as 'Employee Id'.
   * Eg: Please submit ________ to take your survey
   */
  surveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  surveyCodeHelpText: Scalars['String'];
  /** Please submit ________ to take your survey */
  residentSurveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  residentSurveyCodeHelpText: Scalars['String'];
  name: Scalars['String'];
  /** Be careful about changing this since it will change the Survey Portal URL. Do not change it while a survey is active. */
  slug: Scalars['String'];
  /** List of languages that organization admins can enable for their surveys.<br/> */
  languages: Array<LanguageType>;
  /** Controls available employee features for this organization */
  solution?: Maybe<SolutionType>;
  /** Controls available resident features for this organization */
  residentSolution?: Maybe<ResidentSolutionType>;
  /** Participates in US News? */
  participatesInUsNews: Scalars['Boolean'];
  /** Check this if this organization is not a real customer. */
  isTestAccount: Scalars['Boolean'];
  /** Does this organization offer senior living facilities? */
  seniorLiving: Scalars['Boolean'];
  /** Does this organization offer care at home? */
  atHome: Scalars['Boolean'];
  /** Is this organization considered to be a CCRC? */
  isCcrc: Scalars['Boolean'];
  /** Does this organization have more than one location? */
  multipleLocations: Scalars['Boolean'];
  /** Give org access to the score improvement carousel charts for key focus areas. */
  hasKeyFocusAreaCarousel: Scalars['Boolean'];
  /** This field used being used as "security_phrase" when passing leads to Vicidial. */
  vicidialDid: Scalars['String'];
  /** Long-Term Care Tracker Report: Collect Medicare and NCAL #s */
  collectCmsAndNcalNumbers: Scalars['Boolean'];
  /** The integration ID of the organization that is stored within the integrations platform. */
  integrationId?: Maybe<Scalars['String']>;
  certifications: Array<CertificationType>;
  isGptwEnabled: Scalars['Boolean'];
  benchmarkLevelsOfCare: Array<BenchmarkLevelOfCareType>;
  benchmarkDepartments: Array<BenchmarkDepartmentType>;
  benchmarkShifts: Array<BenchmarkShiftType>;
  lastTrustIndexDate?: Maybe<Scalars['Date']>;
  serviceAreas: Array<ServiceAreaType>;
  enabledSolutionFields: Array<SolutionFieldAccessType>;
  activeFilterTypes: Array<FilterTypeType>;
  combinedContactsTemplateUrl?: Maybe<Scalars['String']>;
  employeeContactsTemplateUrl?: Maybe<Scalars['String']>;
};


export type OrganizationTypeActiveFilterTypesArgs = {
  surveyProductType?: Maybe<FilterProductTypeEnum>;
};

export type OverallScoreByFiltersInputType = {
  /** For frontend to associate which filters were applied to which top level groups. */
  label: Scalars['String'];
  dataTypeGroups: Array<DataTypeGroupsInputType>;
};

export type OverallScoreType = {
   __typename?: 'OverallScoreType';
  positive: Scalars['Float'];
  benchmarkPositive?: Maybe<Scalars['Float']>;
  inconsistent: Scalars['Float'];
  benchmarkInconsistent?: Maybe<Scalars['Float']>;
  negative: Scalars['Float'];
  benchmarkNegative?: Maybe<Scalars['Float']>;
  label?: Maybe<Scalars['String']>;
  groupHierarchy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Number of participants who responded for the score */
  totalResponses?: Maybe<Scalars['Int']>;
  /** Number of finished participants in the group */
  count?: Maybe<Scalars['Float']>;
  /** Number of invited participants in the group */
  invitedCount?: Maybe<Scalars['Float']>;
  children: Array<OverallScoreType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
   __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type ParticipantInfo = {
   __typename?: 'ParticipantInfo';
  participantId: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  residentRespondentType?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
};

export type ParticipantInput = {
  surveyCode: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['String']>;
  personalEmail?: Maybe<Scalars['String']>;
  mobilePhone?: Maybe<Scalars['String']>;
  dataTypes: Array<DataTypeInput>;
};

export type ParticipantQuestion = {
   __typename?: 'ParticipantQuestion';
  benchmarkCode?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  text: Scalars['String'];
  category: Scalars['String'];
  isOpenEnded: Scalars['Boolean'];
  isStatement: Scalars['Boolean'];
  choices: Array<ParticipantQuestionChoice>;
};

export type ParticipantQuestionChoice = {
   __typename?: 'ParticipantQuestionChoice';
  benchmarkCode?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  text: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ParticipantSourceEnum {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  CODE = 'CODE',
  PORTAL = 'PORTAL'
}

/** An enumeration. */
export enum ParticipantSourceTypeInput {
  C = 'c',
  E = 'e',
  L = 'l',
  P = 'p',
  S = 's'
}

export type ParticipantsPageType = {
   __typename?: 'ParticipantsPageType';
  participants: Array<ParticipantType>;
  total: Scalars['Int'];
};

export type ParticipantsSectionType = {
   __typename?: 'ParticipantsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  total: Scalars['Int'];
  clientsWithoutParticipantCount: Scalars['Int'];
  workEmails: Scalars['Int'];
  personalEmails: Scalars['Int'];
  mobilePhones: Scalars['Int'];
};

export type ParticipantType = {
   __typename?: 'ParticipantType';
  uuid: Scalars['String'];
  survey: InsightsSurveyNode;
  surveyCode: Scalars['String'];
  personalEmail: Scalars['String'];
  workEmail: Scalars['String'];
  firstName: Scalars['String'];
  mobilePhone?: Maybe<Scalars['String']>;
  dataTypeValues: Array<DataTypeValueType>;
};

/** An enumeration. */
export enum ParticipantTypeEnum {
  CLIENT = 'CLIENT',
  REPRESENTATIVE = 'REPRESENTATIVE'
}

export type PersonalInfoQuestionInput = {
  questionUuid: Scalars['String'];
  text: Scalars['String'];
  choiceUuids: Array<Maybe<Scalars['String']>>;
};

export type PersonalInfoQuestionType = {
   __typename?: 'PersonalInfoQuestionType';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type PublicChoice = {
   __typename?: 'PublicChoice';
  code: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
  benchmarkCode?: Maybe<Scalars['String']>;
  question: PublicQuestion;
  dependsOn?: Maybe<Array<Maybe<PublicChoice>>>;
  children?: Maybe<Array<Maybe<PublicChoice>>>;
  childrenQuestions?: Maybe<Array<PublicQuestion>>;
};

export type PublicConstants = {
   __typename?: 'PublicConstants';
  languages: Array<LanguageType>;
  standardResidentFilterValueChoices: Array<StandardFilterValueChoicesByDataTypeType>;
  fortuneListSubmissionDate: Scalars['Date'];
  trustIndexCertificationMinimumScore: Scalars['Int'];
  cultureBriefMaxImages: Scalars['Int'];
  cultureBriefDisabledFieldsWhenCompleted: Array<Scalars['String']>;
  cultureBriefRequiredFields: Array<Scalars['String']>;
  certificationMarketingToolkitUrl: Scalars['String'];
  questionKindInstructions: Array<AgentInstructionType>;
  questionInstructions: Array<AgentInstructionType>;
};

export type PublicOrganization = {
   __typename?: 'PublicOrganization';
  uuid: Scalars['String'];
  /**
   * Customers can use different names for their survey_code such as 'Employee Id'.
   * Eg: Please submit ________ to take your survey
   */
  surveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  surveyCodeHelpText: Scalars['String'];
  /** Please submit ________ to take your survey */
  residentSurveyCodeName: Scalars['String'];
  /** Any additional instructions to display on the portal page */
  residentSurveyCodeHelpText: Scalars['String'];
  hasLiveSurvey?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Array<Maybe<LanguageType>>>;
  locationFilterTypeUuid?: Maybe<Scalars['String']>;
};

export type PublicParticipant = {
   __typename?: 'PublicParticipant';
  isExpired: Scalars['Boolean'];
  selectedLanguage: Scalars['String'];
  surveyStarted?: Maybe<Scalars['DateTime']>;
  surveySubmitted?: Maybe<Scalars['DateTime']>;
  levelOfCare: Scalars['String'];
  templates?: Maybe<Scalars['JSONString']>;
  location: FilterValueType;
  /**
   * When the survey code refers to a participant with duplicated contact info,
   * return a list of possible participants for the user to choose from.
   */
  duplicatedContactInfo: Array<ParticipantInfo>;
  questions: Array<PublicQuestion>;
  responses: Array<PublicResponse>;
};


export type PublicParticipantQuestionsArgs = {
  personalInfoQuestionsOnly?: Maybe<Scalars['Boolean']>;
};

export type PublicQuestion = {
   __typename?: 'PublicQuestion';
  code: Scalars['String'];
  benchmarkCode?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  kind: QKind;
  category: QCategory;
  visible?: Maybe<Scalars['Boolean']>;
  choices?: Maybe<Array<PublicChoice>>;
  dependsOn?: Maybe<PublicQuestion>;
  children?: Maybe<Array<Maybe<PublicQuestion>>>;
  residentFocus?: Maybe<QResidentFocus>;
  dependsOnChoices?: Maybe<Array<PublicChoice>>;
};

export type PublicResponse = {
   __typename?: 'PublicResponse';
  code: Scalars['String'];
  response?: Maybe<Scalars['String']>;
};

export type PublicSurvey = {
   __typename?: 'PublicSurvey';
  status: SurveyStatusEnum;
  name: Scalars['String'];
  /** Survey start date and time in UTC */
  startDate: Scalars['DateTime'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
  /**
   * Keep the survey results confidential? If False,
   * it will show results with less than 5 responses and
   * enable the Individual Results tab in Insights.
   */
  hasConfidentialResults: Scalars['Boolean'];
  languages: Array<LanguageType>;
  type: SurveyTypeEnum;
  productType: SurveyProductTypeEnum;
  isOpenLinkSurvey: Scalars['Boolean'];
  welcomeMessage: WelcomeMessage;
  translations: Array<PublicTranslation>;
  isCustom: Scalars['Boolean'];
  organization: PublicOrganization;
  participant: PublicParticipant;
  transitionTexts: Array<SurveyTransitionTextType>;
};

export type PublicSurveyType = {
   __typename?: 'PublicSurveyType';
  surveyName: Scalars['String'];
  participantId: Scalars['String'];
  surveySubmitted: Scalars['Boolean'];
  participantIsExpired: Scalars['Boolean'];
};

export type PublicTranslation = {
   __typename?: 'PublicTranslation';
  text: Scalars['String'];
  englishText: Scalars['String'];
  language: LanguageType;
};

export enum QCategory {
  STATEMENTS = 'STATEMENTS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  SENIOR_CARE_DEMOGRAPHICS = 'SENIOR_CARE_DEMOGRAPHICS',
  OPEN_ENDED = 'OPEN_ENDED',
  OTHER = 'OTHER',
  PERSONAL_INFO = 'PERSONAL_INFO'
}

export enum QFocus {
  CUSTOM = 'CUSTOM',
  CREDIBILITY = 'CREDIBILITY',
  RESPECT = 'RESPECT',
  FAIRNESS = 'FAIRNESS',
  PRIDE = 'PRIDE',
  CAMARADERIE = 'CAMARADERIE'
}

export enum QKind {
  STATEMENT = 'STATEMENT',
  SHORT_ANSWER = 'SHORT_ANSWER',
  OPEN_ENDED = 'OPEN_ENDED',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  POSTAL_CODE = 'POSTAL_CODE',
  MULTISELECT = 'MULTISELECT',
  LINEAR = 'LINEAR',
  STATEMENT_EXCELLENCE = 'STATEMENT_EXCELLENCE'
}

/** `MANAGEMENT` questions are now registered under `SERVICE_AREAS`. */
export enum QResidentFocus {
  CUSTOM = 'CUSTOM',
  OVERALL_SATISFACTION = 'OVERALL_SATISFACTION',
  MOVE_IN = 'MOVE_IN',
  MANAGEMENT = 'MANAGEMENT',
  SERVICE_AREAS = 'SERVICE_AREAS',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  OPEN_ENDED = 'OPEN_ENDED',
  ONLINE_REVIEW = 'ONLINE_REVIEW',
  TESTIMONIALS = 'TESTIMONIALS'
}

export enum QResidentResultsGroup {
  DINING = 'DINING',
  PERSONAL_CARE = 'PERSONAL_CARE',
  LICENSED_NURSING = 'LICENSED_NURSING',
  OVERALL_SATISFACTION = 'OVERALL_SATISFACTION',
  OTHER_SERVICES = 'OTHER_SERVICES',
  MANAGEMENT = 'MANAGEMENT',
  CUSTOM = 'CUSTOM'
}

export type Query = {
   __typename?: 'Query';
  currentUser: UserNode;
  /** List of groups the current user is allowed to invite other users to */
  validGroups?: Maybe<Array<GroupNode>>;
  downloads?: Maybe<Array<Maybe<DownloadNode>>>;
  downloadLink?: Maybe<Scalars['String']>;
  /** List the users belonging to the logged in user's org */
  users: UserNodeConnection;
  checkEmail?: Maybe<Scalars['Boolean']>;
  translations: Array<TranslationType>;
  currentCertification?: Maybe<CertificationType>;
  certification: CertificationType;
  certifiedLocationsExport?: Maybe<Scalars['Boolean']>;
  /**
   * Endpoint for returning the surveys list. Some endpoints require the surveys to
   * return the response rates calculations by client, instead of by participant.
   * The 'survey_response_rate_types' input parameter dictates which type each
   * survey should return.
   */
  surveys: SurveyNodeConnection;
  survey: SurveyNode;
  surveyQuestions: Array<QuestionType>;
  questionsByCategory?: Maybe<Array<Maybe<QuestionsByCategory>>>;
  question?: Maybe<QuestionType>;
  /** Fetch a list of questions that can be attached to a survey. */
  availableSurveyQuestions: Array<QuestionType>;
  surveySummary: SurveySummaryType;
  publicConstants: PublicConstants;
  organizationBySlug?: Maybe<PublicOrganization>;
  /** Retrieve survey by uuid or hashid */
  surveyById?: Maybe<PublicSurvey>;
  surveyByParticipantId?: Maybe<PublicSurvey>;
  /** Idenifiers associated with an organization used to group frontend analytics events */
  organizationAnalyticsIdentifiers?: Maybe<AnalyticsIdentifiersType>;
  /** Idenifiers associated with a participant used to group frontend analytics events */
  participantAnalyticsIdentifiers?: Maybe<AnalyticsIdentifiersType>;
  participantsBySurvey: ParticipantsPageType;
  organization?: Maybe<OrganizationType>;
  chartData?: Maybe<Scalars['JSONString']>;
  filterValues: Array<FilterValueType>;
  insightsTestimonialsMediaUrl: Scalars['String'];
  /** A list of scores for each statement on a survey. */
  insightsStatements: Array<StatementScoreType>;
  /** A list of scores for each statement on a survey for each group within a list of data types. */
  insightsStatementsByDataTypes: Array<GroupedStatementScoresType>;
  /** Returns a list of overall index score filtered for each provided set of data type filters. */
  overallIndexScoreByFilters: Array<OverallScoreType>;
  insightsLonelinessSummary: LonelinessScoresType;
  insightsDownload: Scalars['Boolean'];
  actionPlansDownload?: Maybe<Scalars['Boolean']>;
  /**
   * Data that's fetched before a response rate report so that public and logged in
   * reports can fetch the report with the same default data
   */
  responseRateReportMetadata?: Maybe<ResponseRateReportMetadata>;
  responseRateReport?: Maybe<ResponseRateReportDataType>;
  completionRateReport?: Maybe<CompletionRateReportDataType>;
  responsesByDate: Array<ResponsesByDateType>;
  responseRateSurvey: ResponseRateSurveyType;
  responseRateByFilterType?: Maybe<Array<Maybe<FilterTypeResponse>>>;
  insightsOverallIndex: OverallScoreType;
  insightsEmployeeEligibilityResponseRate: EmployeeEligibilityRate;
  insightsDimensionsOverview: Array<OverallScoreType>;
  insightsCulture?: Maybe<CultureOverviewType>;
  insightsCultureChange?: Maybe<CultureChangeOverviewType>;
  /** Positive statement score for the location closest to the given percentile. */
  insightsTargetScore?: Maybe<Scalars['Float']>;
  /** The potential overall score if some of the lowest scores were improved to average. */
  insightsPotentialImprovementScore: ImprovementScoreType;
  /**
   * Endpoint to determine demographics with the highest impact, as measured by
   *             size of demographic groups and their difference in score.
   */
  insightsKeyDemographics: Array<GroupedScoresType>;
  insightsHeatmap?: Maybe<HeatmapType>;
  insightsHeatmapControls: HeatmapControls;
  insightsHeatmapBenchmarkScores: HeatmapBenchmarkScores;
  insightsNpsGroupScores: NpsGroupScoresType;
  /** Returns a list of NPS group scores, grouped by a data type. */
  insightsNpsGroupScoresByDataType?: Maybe<Array<NpsGroupScoresType>>;
  /** Return a list of counts summed from multiselect answers. */
  insightsMultiselectGroupCounts: Array<GroupCountsType>;
  /** Return a list of voluntary/involuntary counts per provided data type. */
  insightsVoluntaryDischargeCountsBy: Array<VoluntaryDischargeGroupCountsType>;
  /** Return summary of positive (3-5) responses to the Core Q questions and an overall CoreQ score. */
  insightsCoreQResults: Array<StatementScoreType>;
  insightsCustomSurvey: Array<InsightsCustomSurveyQuestion>;
  insightsComparisonControls: ComparisonToolControls;
  insightsOpenEndedQuestions: Array<Maybe<FrozenQuestionType>>;
  insightsComments: InsightsComments;
  insightsIndividualResults: Array<IndividualResultType>;
  insightsBenchmarks: Array<BenchmarkNode>;
  insightsStatementBenchmark?: Maybe<StatementBenchmarkType>;
  /**
   * Returns a list of the groups available for a particular data type in the provided surveys.
   * When `for_dt_code` is not supplied, assumes that we are looking at all the survey filter types.
   * When multiple surveys are provided, it returns the intersection of values used in both surveys.
   */
  insightsSurveysFilterValues: Array<DataTypeGroupsType>;
  /** Returns a list of the group names available for a particular data type */
  insightsGroupsForDtCode: Array<Scalars['String']>;
  /** Return a list of scores grouped by a data type. */
  insightsScoresByDataType: Array<OverallScoreType>;
  /** Return a list of scores grouped by a multi-level data type hierarchy (e.g. ['ai_location', 'ai_dept']). */
  insightsScoresByDataTypeHierarchy: Scalars['String'];
  /** A list of `insights_scores_by_data_type` for each data type */
  insightsScoresByDataTypes: Array<GroupedScoresType>;
  /** Group of users in the organization associated with a set of permissions */
  groups: Array<GroupNode>;
  /** List the actions plans for the Action Plans Management page */
  actionPlans?: Maybe<ActionPlanNodeConnection>;
  /** Get a summary count of all action plans and tasks */
  actionPlansCounts: ActionPlanCountsType;
  /** Get a summary of all action plans activity */
  actionPlansActivity: Array<ActionPlanActivityType>;
  actionPlan: ActionPlanNode;
};


export type QueryDownloadLinkArgs = {
  uuid: Scalars['String'];
};


export type QueryUsersArgs = {
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  search?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<Scalars['String']>;
  sortDescending?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryCheckEmailArgs = {
  email: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
};


export type QueryTranslationsArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryCertificationArgs = {
  certificationUuid: Scalars['String'];
};


export type QueryCertifiedLocationsExportArgs = {
  certificationUuid: Scalars['String'];
};


export type QuerySurveysArgs = {
  surveyProductType?: Maybe<SurveyProductTypeEnum>;
  surveyType?: Maybe<SurveyTypeEnum>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySurveyArgs = {
  surveyUuid: Scalars['String'];
};


export type QuerySurveyQuestionsArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryQuestionsByCategoryArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryQuestionArgs = {
  surveyUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type QueryAvailableSurveyQuestionsArgs = {
  surveyUuid: Scalars['String'];
};


export type QuerySurveySummaryArgs = {
  responseRateByClient: Scalars['Boolean'];
  surveyUuid: Scalars['String'];
};


export type QueryOrganizationBySlugArgs = {
  slug: Scalars['String'];
  surveyProductType: SurveyProductTypeEnum;
};


export type QuerySurveyByIdArgs = {
  surveyId: Scalars['String'];
};


export type QuerySurveyByParticipantIdArgs = {
  participantId: Scalars['String'];
  source?: Maybe<ParticipantSourceTypeInput>;
};


export type QueryOrganizationAnalyticsIdentifiersArgs = {
  slug: Scalars['String'];
};


export type QueryParticipantAnalyticsIdentifiersArgs = {
  participantId: Scalars['String'];
};


export type QueryParticipantsBySurveyArgs = {
  surveyUuid: Scalars['String'];
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
  sortBy?: Maybe<Scalars['String']>;
  sortDescending?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryChartDataArgs = {
  chartType: Scalars['String'];
  surveyId: Scalars['Int'];
};


export type QueryFilterValuesArgs = {
  dtCode: Scalars['String'];
};


export type QueryInsightsStatementsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  limit?: Maybe<Scalars['Int']>;
  includeCustom?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsStatementsByDataTypesArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dataTypes: Array<DataTypeGroupsInputType>;
  rankBy?: Maybe<RankByEnum>;
  limit?: Maybe<Scalars['Int']>;
  includeCustom?: Maybe<Scalars['Boolean']>;
};


export type QueryOverallIndexScoreByFiltersArgs = {
  surveyUuid: Scalars['String'];
  dataTypeFilters: Array<Maybe<OverallScoreByFiltersInputType>>;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsLonelinessSummaryArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsDownloadArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  downloadType: UserDownloadsEnum;
  certificationReportLocationUuid?: Maybe<Scalars['String']>;
  filterType1Code?: Maybe<Scalars['String']>;
  filterType2Code?: Maybe<Scalars['String']>;
  byClient?: Maybe<Scalars['Boolean']>;
};


export type QueryActionPlansDownloadArgs = {
  userUuid?: Maybe<Scalars['String']>;
};


export type QueryResponseRateReportMetadataArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type QueryResponseRateReportArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  groupByFilterType1Code: Scalars['String'];
  groupByFilterType2Code: Scalars['String'];
  byClient?: Maybe<Scalars['Boolean']>;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryCompletionRateReportArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  groupByFilterType1Code: Scalars['String'];
  groupByFilterType2Code: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryResponsesByDateArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  byClient?: Maybe<Scalars['Boolean']>;
};


export type QueryResponseRateSurveyArgs = {
  surveyUuid?: Maybe<Scalars['String']>;
  reportUuid?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type QueryResponseRateByFilterTypeArgs = {
  surveyUuid: Scalars['String'];
  filterTypeUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  byClient?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsOverallIndexArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  includeCustom?: Maybe<Scalars['Boolean']>;
  limitToCoreQEligible?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsEmployeeEligibilityResponseRateArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsDimensionsOverviewArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsCultureArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  demographics?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryInsightsCultureChangeArgs = {
  surveyUuid1: Scalars['String'];
  surveyUuid2: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsTargetScoreArgs = {
  surveyUuid: Scalars['String'];
  statementCode: Scalars['String'];
  targetPercentile: Scalars['Int'];
};


export type QueryInsightsPotentialImprovementScoreArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCode?: Maybe<Scalars['String']>;
};


export type QueryInsightsKeyDemographicsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  impactScoreStrategy: ImpactScoreStrategyEnum;
};


export type QueryInsightsHeatmapArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  y: Scalars['String'];
};


export type QueryInsightsHeatmapControlsArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsHeatmapBenchmarkScoresArgs = {
  surveyUuid: Scalars['String'];
  benchmark: BenchmarkNodeInput;
};


export type QueryInsightsNpsGroupScoresArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
};


export type QueryInsightsNpsGroupScoresByDataTypeArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCode: Scalars['String'];
};


export type QueryInsightsMultiselectGroupCountsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  code: Scalars['String'];
};


export type QueryInsightsVoluntaryDischargeCountsByArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCode: Scalars['String'];
};


export type QueryInsightsCoreQResultsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  limitToCoreQEligible: Scalars['Boolean'];
};


export type QueryInsightsCustomSurveyArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
};


export type QueryInsightsComparisonControlsArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsOpenEndedQuestionsArgs = {
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsCommentsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  questionCode: Scalars['String'];
  pageSize: Scalars['Int'];
  page: Scalars['Int'];
  searchWords?: Maybe<Array<Maybe<Scalars['String']>>>;
  sortBy?: Maybe<Scalars['String']>;
  filterBy?: Maybe<NpsGroupsEnum>;
};


export type QueryInsightsIndividualResultsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryInsightsBenchmarksArgs = {
  surveyUuid: Scalars['String'];
};


export type QueryInsightsStatementBenchmarkArgs = {
  benchmarkUuid: Scalars['String'];
  statementCode: Scalars['String'];
};


export type QueryInsightsSurveysFilterValuesArgs = {
  surveyUuids: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  forDtCode?: Maybe<DataTypeCode>;
  isFilterType?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsGroupsForDtCodeArgs = {
  surveyUuid: Scalars['String'];
  dtCode: DataTypeCode;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsScoresByDataTypeArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCode: Scalars['String'];
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  includeAllSurveyChoices?: Maybe<Scalars['Boolean']>;
  limitToCoreQEligible?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsScoresByDataTypeHierarchyArgs = {
  surveyUuid: Scalars['String'];
  dtCodeHierarchy: Array<Scalars['String']>;
  filters?: Maybe<Array<Scalars['String']>>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
  includeAllSurveyChoices?: Maybe<Scalars['Boolean']>;
};


export type QueryInsightsScoresByDataTypesArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  surveyUuid: Scalars['String'];
  filters?: Maybe<Array<Scalars['String']>>;
  benchmark?: Maybe<BenchmarkNodeInput>;
  dtCodes: Array<Scalars['String']>;
  statementCodes?: Maybe<Array<Scalars['String']>>;
  rankBy?: Maybe<RankByEnum>;
};


export type QueryGroupsArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueryActionPlansArgs = {
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  sortBy: ActionPlansSortByEnum;
  sortAscending: Scalars['Boolean'];
  selectedFollowFilters: Array<Maybe<Scalars['String']>>;
  searchQuery?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryActionPlansCountsArgs = {
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  selectedFollowFilters: Array<Maybe<Scalars['String']>>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryActionPlansActivityArgs = {
  pastDays: Array<Scalars['Int']>;
  selectedFollowFilters: Array<Maybe<Scalars['String']>>;
  filterValueUuids?: Maybe<Array<Scalars['String']>>;
  searchQuery?: Maybe<Scalars['String']>;
};


export type QueryActionPlanArgs = {
  userUuid?: Maybe<Scalars['String']>;
};

export type QuestionChoiceInput = {
  uuid?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type QuestionChoiceType = {
   __typename?: 'QuestionChoiceType';
  uuid: Scalars['String'];
  text: Scalars['String'];
  benchmarkCode: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type QuestionFiltersType = {
   __typename?: 'QuestionFiltersType';
  levelsOfCare?: Maybe<Array<Maybe<Scalars['String']>>>;
  participantTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QuestionInput = {
  questionUuid?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  category?: Maybe<QCategory>;
  kind?: Maybe<QKind>;
  index?: Maybe<Scalars['Int']>;
  choices: Array<QuestionChoiceInput>;
};

export type QuestionResponse = {
  code: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type QuestionsByCategory = {
   __typename?: 'QuestionsByCategory';
  category?: Maybe<QCategory>;
  canAddCustom?: Maybe<Scalars['Boolean']>;
  questionsByFocus?: Maybe<Array<Maybe<QuestionsByFocusType>>>;
};

export type QuestionsByFocusType = {
   __typename?: 'QuestionsByFocusType';
  focus?: Maybe<QFocus>;
  canAddCustom?: Maybe<Scalars['Boolean']>;
  questions?: Maybe<Array<Maybe<QuestionType>>>;
};

export type QuestionsSectionType = {
   __typename?: 'QuestionsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  trustIndex?: Maybe<Scalars['Int']>;
  demographic?: Maybe<Scalars['Int']>;
  openEnded?: Maybe<Scalars['Int']>;
  npsQuestions?: Maybe<Scalars['Int']>;
  custom?: Maybe<Scalars['Int']>;
  shortAnswer?: Maybe<Scalars['Int']>;
  longAnswer?: Maybe<Scalars['Int']>;
  multipleChoice?: Maybe<Scalars['Int']>;
  multiselect?: Maybe<Scalars['Int']>;
  linearScale?: Maybe<Scalars['Int']>;
  statements?: Maybe<Scalars['Int']>;
  locationsCount?: Maybe<Scalars['Int']>;
  locationsWithOnlineReviewSitesCount?: Maybe<Scalars['Int']>;
  hasOnlineReviewQuestion?: Maybe<Scalars['Boolean']>;
  personalInfoQuestions?: Maybe<Array<PersonalInfoQuestionType>>;
};

export type QuestionType = {
   __typename?: 'QuestionType';
  uuid: Scalars['String'];
  benchmarkCode?: Maybe<BenchmarkCodeType>;
  kind: QKind;
  focus?: Maybe<QFocus>;
  residentFocus?: Maybe<QResidentFocus>;
  category?: Maybe<QCategory>;
  dependsOn?: Maybe<QuestionType>;
  filters?: Maybe<QuestionFiltersType>;
  isCustom: Scalars['Boolean'];
  isDisabled: Scalars['Boolean'];
  required?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  choices: Array<QuestionChoiceType>;
  availableChoices: Array<QuestionChoiceType>;
  infoLabel?: Maybe<Scalars['String']>;
  isOpenEnded: Scalars['Boolean'];
  isStatement: Scalars['Boolean'];
  text: Scalars['String'];
};

/** An enumeration. */
export enum RankByEnum {
  POSITIVE = 'POSITIVE',
  INCONSISTENT = 'INCONSISTENT',
  NEGATIVE = 'NEGATIVE',
  OVERALL_POSITIVE_CORRELATION = 'OVERALL_POSITIVE_CORRELATION'
}

export type ReasonForLeaving = {
   __typename?: 'ReasonForLeaving';
  value: Scalars['String'];
  isVoluntary?: Maybe<Scalars['Boolean']>;
};

export type RemoveParticipantsUpload = {
   __typename?: 'RemoveParticipantsUpload';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type ResetIntervalNotification = {
   __typename?: 'ResetIntervalNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  notification: IntervalNotificationType;
};

export type ResetScheduledNotification = {
   __typename?: 'ResetScheduledNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  notification: ScheduledNotificationType;
};

export type ResidentBenchmarkType = {
   __typename?: 'ResidentBenchmarkType';
  name: Scalars['String'];
};

/** An enumeration. */
export enum ResidentSolutionBenchmarkPastSurveys {
  /** None */
  NONE = 'NONE',
  /** Previous Survey(s) */
  PREVIOUS = 'PREVIOUS',
  /** All */
  ALL = 'ALL'
}

/** An enumeration. */
export enum ResidentSolutionDataAccessLevels {
  /** No ability to set access permissions */
  NONE = 'NONE',
  /** Can use all filter types for setting permissions */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE',
  /** Users can pick one out of all available filters */
  CHOOSE_ONE = 'CHOOSE_ONE'
}

/** An enumeration. */
export enum ResidentSolutionFilters {
  /** No ability to use filter types to filter data */
  NONE = 'NONE',
  /** Can use all filter types */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE'
}

export type ResidentSolutionType = Node & {
   __typename?: 'ResidentSolutionType';
  name: Scalars['String'];
  /** If this is checked, admins can only invite other admins. */
  adminInvitesOnly: Scalars['Boolean'];
  /** How many levels of data access can admins assign? */
  dataAccessLevels: ResidentSolutionDataAccessLevels;
  /** How many filters are available in Insights */
  filters: ResidentSolutionFilters;
  /** Does this solution allow access to open link surveys? */
  openLinkSurveys: Scalars['Boolean'];
  /** Display tabs not included in solution with sales promo tooltip */
  showUnavailableTabs: Scalars['Boolean'];
  /** Whether the organization has access to insights or not */
  insights: Scalars['Boolean'];
  /** Give access to extended snapshots? */
  insightsSnapshots: Scalars['Boolean'];
  /** Give access to key demographics within snapshots? */
  insightsKeyDemographics: Scalars['Boolean'];
  /** Access to Statements Tab? */
  insightsStatements: Scalars['Boolean'];
  /** Access to Comments Tab? */
  insightsComments: Scalars['Boolean'];
  /** Show additional features on comments page such as word cloud and sorting. */
  insightsEnhancedComments: Scalars['Boolean'];
  /** Remove the Second Filter column from the comments download */
  insightsHideSecondFilter: Scalars['Boolean'];
  /** Access to Comparisons Tab? */
  insightsComparisons: Scalars['Boolean'];
  /** Access to the grid view on the Comparisons Tab? */
  insightsComparisonsGrid: Scalars['Boolean'];
  /** Access to Heatmap Tab? */
  insightsHeatmap: Scalars['Boolean'];
  /** Access to Time Trending? */
  insightsTimeTrending: Scalars['Boolean'];
  /** Access to download a static PDF for Action Plans? */
  insightsActionPlanPdf: Scalars['Boolean'];
  /** Allow downloading statements results excel */
  statementsExcelDownload: Scalars['Boolean'];
  /** Allow downloading comments results excel */
  commentsExcelDownload: Scalars['Boolean'];
  /** Access to Action Plans? */
  actionPlans: Scalars['Boolean'];
  /** Allow Custom Statements on Action Plans? */
  actionPlansCustomStatements: Scalars['Boolean'];
  /** Enable Action Plans management? */
  actionPlansManagement: Scalars['Boolean'];
  /** Which surveys to show in benchmarks menu? */
  benchmarkPastSurveys: ResidentSolutionBenchmarkPastSurveys;
  /** Allow user to benchmark against their structure data */
  benchmarkInternal: Scalars['Boolean'];
  /** Access to engagement overlay? */
  engagementOverlay: Scalars['Boolean'];
  /** The maximum number of notifications allowed per survey. */
  maxSurveyNotifications: Scalars['Int'];
  /** Allow users to setup interval based notifications? */
  hasIntervalBasedNotifications: Scalars['Boolean'];
  /** Should users be able to configure and receive notifications when there are new survey responses? */
  hasSurveyResponseNotifications: Scalars['Boolean'];
  /** Should users be able to configure and receive score based notifications? */
  hasScoreNotifications: Scalars['Boolean'];
  /** Access to Insights Testimonials? */
  insightsTestimonials: Scalars['Boolean'];
  /** Allow downloading NPS results? */
  npsDownload: Scalars['Boolean'];
  /** Orgs can only enable standard services (mgmt, dining, caregiving, etc.). See confluence for full list. */
  standardServicesOnly: Scalars['Boolean'];
  /** Allow downloading the summary results report? */
  resultsSummaryReportDownload: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
  onlineReviewSites?: Maybe<Array<OnlineReviewSiteEnum>>;
};

export type ResponseRateFilterType = {
   __typename?: 'ResponseRateFilterType';
  filterType: FilterTypeType;
  /**
   * Some filter types should not be used for response rate by client
   * because the values can differ between client and representative and we wouldn't
   * be able to consolidate them into a single response.
   */
  isValidByClient: Scalars['Boolean'];
};

export type ResponseRateHighlightType = {
   __typename?: 'ResponseRateHighlightType';
  filterTypeName: Scalars['String'];
  filterTypeUuid: Scalars['String'];
  topFilterValueNames: Array<Maybe<Scalars['String']>>;
  topRate: Scalars['Float'];
  highlightType: Scalars['String'];
};

export type ResponseRateReportDataType = {
   __typename?: 'ResponseRateReportDataType';
  responseRate: ResponseRateReportType;
  rows: Array<ResponseRateRowType>;
  highlights: Array<ResponseRateHighlightType>;
};

export type ResponseRateReportMetadata = {
   __typename?: 'ResponseRateReportMetadata';
  surveyUuid: Scalars['String'];
  isDefaultClientBasedResponseRateReport: Scalars['Boolean'];
  filterTypes: Array<ResponseRateFilterType>;
  minShowableResults: Scalars['Int'];
  distributionType: SurveyDistributionTypeEnum;
};

export type ResponseRateReportType = {
   __typename?: 'ResponseRateReportType';
  finished: Scalars['Int'];
  total: Scalars['Int'];
  rate: Scalars['Float'];
  totalFilter1: Scalars['Int'];
};

export type ResponseRateRowType = {
   __typename?: 'ResponseRateRowType';
  total: Scalars['Int'];
  finished: Scalars['Int'];
  rate: Scalars['Float'];
  filterValue1: Scalars['String'];
  filterValue2: Scalars['String'];
};

export type ResponseRateSurveyType = {
   __typename?: 'ResponseRateSurveyType';
  uuid: Scalars['String'];
  status: SurveyStatusEnum;
  name: Scalars['String'];
  /** Survey start date and time in UTC */
  startDate: Scalars['DateTime'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
  targetResponsesNo?: Maybe<Scalars['Int']>;
  reportUuid: Scalars['String'];
  type: SurveyTypeEnum;
  reportShareUrl: Scalars['String'];
  certificationEligibilityRate: Scalars['Float'];
  isDefaultClientBasedResponseRateReport: Scalars['Boolean'];
  minShowableResults: Scalars['Int'];
  distributionType?: Maybe<SurveyDistributionTypeEnum>;
};

export type ResponseRateType = {
   __typename?: 'ResponseRateType';
  finished: Scalars['Int'];
  total: Scalars['Int'];
  rate: Scalars['Float'];
};

/** An enumeration. */
export enum ResponseRateTypeEnum {
  CLIENT = 'CLIENT',
  PARTICIPANT = 'PARTICIPANT'
}

export type ResponsesByDateType = {
   __typename?: 'ResponsesByDateType';
  date: Scalars['Date'];
  responses: Scalars['Int'];
};

export type ResultsSurveyNode = {
   __typename?: 'ResultsSurveyNode';
  uuid: Scalars['String'];
  name: Scalars['String'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
};

export type SavePersonalInfoResponses = {
   __typename?: 'SavePersonalInfoResponses';
  errors?: Maybe<Array<Scalars['String']>>;
  participantHashId?: Maybe<Scalars['String']>;
};

export type SaveResponses = {
   __typename?: 'SaveResponses';
  errors?: Maybe<Array<Scalars['String']>>;
  responses?: Maybe<Scalars['JSONString']>;
};

export type ScheduledNotificationInput = {
  uuid?: Maybe<Scalars['String']>;
  workEmail?: Maybe<Scalars['Boolean']>;
  personalEmail?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  smsOptional?: Maybe<Scalars['String']>;
  emailSubject?: Maybe<Scalars['String']>;
  emailTextOptional1?: Maybe<Scalars['String']>;
  emailTextOptional2?: Maybe<Scalars['String']>;
  when?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum ScheduledNotificationNotificationType {
  /** Starting */
  SURVEY_STARTING = 'SURVEY_STARTING',
  /** Reminder */
  REMINDER = 'REMINDER',
  /** Ending */
  SURVEY_ENDING = 'SURVEY_ENDING',
  /** Other */
  OTHER = 'OTHER'
}

export type ScheduledNotificationType = {
   __typename?: 'ScheduledNotificationType';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  /** The part of the SMS that can be customized. */
  smsOptional: Scalars['String'];
  /** The required part of the SMS. */
  smsRequired: Scalars['String'];
  /** This is NOT customizable. */
  emailSubject: Scalars['String'];
  /** The first part of the email that can be customized. */
  emailTextOptional1: Scalars['String'];
  /** The required part of the email. */
  emailTextRequired: Scalars['String'];
  /** The second part of the email that can be customized. */
  emailTextOptional2: Scalars['String'];
  notificationType: ScheduledNotificationNotificationType;
  sms: Scalars['Boolean'];
  personalEmail: Scalars['Boolean'];
  workEmail: Scalars['Boolean'];
  /** Is this notification set as active/usable? */
  active: Scalars['Boolean'];
  /** Date and time in UTC. */
  when: Scalars['DateTime'];
  survey: ResultsSurveyNode;
  errors?: Maybe<Scalars['String']>;
  firstSentTimestamp?: Maybe<Scalars['DateTime']>;
};

export type SendIntervalTestEmail = {
   __typename?: 'SendIntervalTestEmail';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SendIntervalTestSms = {
   __typename?: 'SendIntervalTestSMS';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SendScheduledTestEmail = {
   __typename?: 'SendScheduledTestEmail';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SendScheduledTestSms = {
   __typename?: 'SendScheduledTestSMS';
  errors?: Maybe<Array<Scalars['String']>>;
};

/**
 * An enum of service areas that can be associated with an organization. The ServiceArea model on the
 * organization determines which service-area related questions show up on a resident survey.
 * Some services include multiple questions (e.g. dining_1, dining_2). The majority are "generic_choices"
 * that include a single related question: "Are you satisfied with {{service_area}}".
 */
export enum ServiceAreaEnum {
  ACCOUNT_BILLING = 'ACCOUNT_BILLING',
  ADMISSIONS = 'ADMISSIONS',
  BUILDING = 'BUILDING',
  CAREGIVING = 'CAREGIVING',
  CLINIC = 'CLINIC',
  COMMUNITY = 'COMMUNITY',
  DAY_CARE_ADULT = 'DAY_CARE_ADULT',
  DAY_CARE_CHILDREN = 'DAY_CARE_CHILDREN',
  DENTIST_SPECIALISTS = 'DENTIST_SPECIALISTS',
  DINING = 'DINING',
  ENRICHMENT_ACTIVITIES = 'ENRICHMENT_ACTIVITIES',
  ENVIRONMENT_SERVICES = 'ENVIRONMENT_SERVICES',
  FOUNDATION_PHILANTHROPY = 'FOUNDATION_PHILANTHROPY',
  FRONT_DESK = 'FRONT_DESK',
  HOME_CARE = 'HOME_CARE',
  HOME_HEALTH = 'HOME_HEALTH',
  HOSPICE = 'HOSPICE',
  HOUSEKEEPING = 'HOUSEKEEPING',
  IT_INFORMATION_SYSTEMS = 'IT_INFORMATION_SYSTEMS',
  LANDSCAPING_GROUNDS = 'LANDSCAPING_GROUNDS',
  LAUNDRY = 'LAUNDRY',
  LICENSED_NURSING = 'LICENSED_NURSING',
  MAINTENANCE = 'MAINTENANCE',
  MANAGEMENT = 'MANAGEMENT',
  MARKETING_OR_ADMISSIONS = 'MARKETING_OR_ADMISSIONS',
  MARKET_STORE = 'MARKET_STORE',
  MEDICAL = 'MEDICAL',
  MEDICATION_TECHNICIANS = 'MEDICATION_TECHNICIANS',
  OCCUPATIONAL_THERAPY = 'OCCUPATIONAL_THERAPY',
  PARKING = 'PARKING',
  PHYSICAL_THERAPY = 'PHYSICAL_THERAPY',
  SAFETY = 'SAFETY',
  SALON = 'SALON',
  SERVICE_COORDINATORS = 'SERVICE_COORDINATORS',
  SOCIAL_WORK = 'SOCIAL_WORK',
  SPEECH_LANGUAGE_PATHOLOGY = 'SPEECH_LANGUAGE_PATHOLOGY',
  SPIRITUAL_SERVICES = 'SPIRITUAL_SERVICES',
  TRANSPORTATION = 'TRANSPORTATION',
  UNIT = 'UNIT',
  WELLNESS_FITNESS = 'WELLNESS_FITNESS'
}

export type ServiceAreaInputType = {
  code: ServiceAreaEnum;
  customLabel: Scalars['String'];
  levelOfCareCodes: Array<Scalars['String']>;
  isActive: Scalars['Boolean'];
  departmentUuids: Array<Scalars['String']>;
};

export type ServiceAreaType = {
   __typename?: 'ServiceAreaType';
  uuid: Scalars['String'];
  code: ServiceAreaEnum;
  /** Optional label to override default enum name */
  customLabel: Scalars['String'];
  /** Include service area in resident survey questions */
  isActive: Scalars['Boolean'];
  questionCodes: Array<Scalars['String']>;
  /** How the service area appears on a question. */
  questionLabel: Scalars['String'];
  /** Default enum label */
  label: Scalars['String'];
  departments: Array<FilterValueType>;
  levelsOfCare: Array<ServiceLevelOfCareType>;
  /** Whether the service can be disabled */
  required: Scalars['Boolean'];
  /** Whether the service can be enabled */
  disabled: Scalars['Boolean'];
};

export type ServiceLevelOfCareType = {
   __typename?: 'ServiceLevelOfCareType';
  code: Scalars['String'];
  name: Scalars['String'];
  /** Whether the level of care can be disabled */
  required: Scalars['Boolean'];
};

export type SettingsSectionType = {
   __typename?: 'SettingsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  surveyCodeName: Scalars['String'];
  surveyCodeHelpText: Scalars['String'];
  locs?: Maybe<Array<Scalars['String']>>;
  depts?: Maybe<Array<Scalars['String']>>;
  missingServices: Array<Scalars['String']>;
};

export type ShareMarketingToolkit = {
   __typename?: 'ShareMarketingToolkit';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type ShareScreenshot = {
   __typename?: 'ShareScreenshot';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type SignedUrl = {
   __typename?: 'SignedUrl';
  url: Scalars['String'];
  path: Scalars['String'];
};

/** An enumeration. */
export enum SolutionBenchmarkPastSurveys {
  /** None */
  NONE = 'NONE',
  /** Previous Survey(s) */
  PREVIOUS = 'PREVIOUS',
  /** All */
  ALL = 'ALL'
}

/** An enumeration. */
export enum SolutionDataAccessLevels {
  /** No ability to set access permissions */
  NONE = 'NONE',
  /** Can use all filter types for setting permissions */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE',
  /** Users can pick one out of all available filters */
  CHOOSE_ONE = 'CHOOSE_ONE'
}

export type SolutionFieldAccessType = {
   __typename?: 'SolutionFieldAccessType';
  field: SolutionFieldEnum;
  enabled: Scalars['Boolean'];
};

/** An enumeration. */
export enum SolutionFieldEnum {
  SURVEYS = 'SURVEYS',
  PULSE_SURVEYS = 'PULSE_SURVEYS',
  MAX_PULSE_SURVEYS = 'MAX_PULSE_SURVEYS',
  CUSTOM_SURVEYS = 'CUSTOM_SURVEYS',
  MAX_CUSTOM_SURVEYS = 'MAX_CUSTOM_SURVEYS',
  HAS_MONTHLY_SURVEYS = 'HAS_MONTHLY_SURVEYS',
  HAS_END_OF_EMPLOYMENT_SURVEYS = 'HAS_END_OF_EMPLOYMENT_SURVEYS',
  HAS_ENGAGEMENT_MONTHLY_SURVEYS = 'HAS_ENGAGEMENT_MONTHLY_SURVEYS',
  HAS_START_OF_SERVICE_SURVEYS = 'HAS_START_OF_SERVICE_SURVEYS',
  HAS_END_OF_SERVICE_SURVEYS = 'HAS_END_OF_SERVICE_SURVEYS',
  HAS_DISCHARGE_SURVEYS = 'HAS_DISCHARGE_SURVEYS',
  MAX_DISCHARGE_SURVEYS = 'MAX_DISCHARGE_SURVEYS',
  MAX_START_OF_SERVICE = 'MAX_START_OF_SERVICE',
  MAX_END_OF_SERVICE = 'MAX_END_OF_SERVICE',
  ACTION_PLANS_CUSTOM_STATEMENTS = 'ACTION_PLANS_CUSTOM_STATEMENTS',
  ACTION_PLANS = 'ACTION_PLANS',
  ACTION_PLANS_MANAGEMENT = 'ACTION_PLANS_MANAGEMENT',
  ADMIN_INVITES_ONLY = 'ADMIN_INVITES_ONLY',
  INSIGHTS_ACTION_PLAN_PDF = 'INSIGHTS_ACTION_PLAN_PDF',
  ENGAGEMENT_OVERLAY = 'ENGAGEMENT_OVERLAY',
  STANDARD_SERVICES_ONLY = 'STANDARD_SERVICES_ONLY',
  HAS_SURVEY_RESPONSE_NOTIFICATIONS = 'HAS_SURVEY_RESPONSE_NOTIFICATIONS',
  HAS_SCORE_BASED_NOTIFICATIONS = 'HAS_SCORE_BASED_NOTIFICATIONS'
}

/** An enumeration. */
export enum SolutionFilters {
  /** No ability to use filter types to filter data */
  NONE = 'NONE',
  /** Can use all filter types */
  ALL = 'ALL',
  /** Can use one filter type that we specify */
  ONE = 'ONE'
}

export type SolutionType = Node & {
   __typename?: 'SolutionType';
  name: Scalars['String'];
  /** If this is checked, admins can only invite other admins. */
  adminInvitesOnly: Scalars['Boolean'];
  /** How many levels of data access can admins assign? */
  dataAccessLevels: SolutionDataAccessLevels;
  /** How many filters are available in Insights */
  filters: SolutionFilters;
  /** Display tabs not included in solution with sales promo tooltip */
  showUnavailableTabs: Scalars['Boolean'];
  /** Whether the organization has access to insights or not */
  insights: Scalars['Boolean'];
  /** Give access to extended snapshots? */
  insightsSnapshots: Scalars['Boolean'];
  /** Give access to key demographics within snapshots? */
  insightsKeyDemographics: Scalars['Boolean'];
  /** Access to Statements Tab? */
  insightsStatements: Scalars['Boolean'];
  /** Access to Comments Tab? */
  insightsComments: Scalars['Boolean'];
  /** Show additional features on comments page such as word cloud and sorting. */
  insightsEnhancedComments: Scalars['Boolean'];
  /** Remove the Second Filter column from the comments download */
  insightsHideSecondFilter: Scalars['Boolean'];
  /** Access to Comparisons Tab? */
  insightsComparisons: Scalars['Boolean'];
  /** Access to the grid view on the Comparisons Tab? */
  insightsComparisonsGrid: Scalars['Boolean'];
  /** Access to Heatmap Tab? */
  insightsHeatmap: Scalars['Boolean'];
  /** Access to Time Trending? */
  insightsTimeTrending: Scalars['Boolean'];
  /** Access to download a static PDF for Action Plans? */
  insightsActionPlanPdf: Scalars['Boolean'];
  /** Allow downloading statements results excel */
  statementsExcelDownload: Scalars['Boolean'];
  /** Allow downloading comments results excel */
  commentsExcelDownload: Scalars['Boolean'];
  /** Access to Action Plans? */
  actionPlans: Scalars['Boolean'];
  /** Allow Custom Statements on Action Plans? */
  actionPlansCustomStatements: Scalars['Boolean'];
  /** Enable Action Plans management? */
  actionPlansManagement: Scalars['Boolean'];
  /** Which surveys to show in benchmarks menu? */
  benchmarkPastSurveys: SolutionBenchmarkPastSurveys;
  /** Allow user to benchmark against their structure data */
  benchmarkInternal: Scalars['Boolean'];
  /** Access to engagement overlay? */
  engagementOverlay: Scalars['Boolean'];
  /** The maximum number of notifications allowed per survey. */
  maxSurveyNotifications: Scalars['Int'];
  /** Allow users to setup interval based notifications? */
  hasIntervalBasedNotifications: Scalars['Boolean'];
  /** Access to Certification Report PDF? */
  certificationReport: Scalars['Boolean'];
  /** Access to Engagement Report PDF? */
  engagementReport: Scalars['Boolean'];
  certificationType?: Maybe<Scalars['String']>;
  /** Allow downloading eNPS results? */
  npsDownload: Scalars['Boolean'];
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type StandardFilterValueChoicesByDataTypeType = {
   __typename?: 'StandardFilterValueChoicesByDataTypeType';
  dtCode: DataTypeCode;
  standardChoices: Array<Scalars['String']>;
};

export type StandardType = {
   __typename?: 'StandardType';
  text: Scalars['String'];
  value: Scalars['String'];
};

export type StatementBenchmarkType = {
   __typename?: 'StatementBenchmarkType';
  residentBenchmark?: Maybe<ResidentBenchmarkType>;
  positive?: Maybe<Scalars['Int']>;
  inconsistent?: Maybe<Scalars['Int']>;
  negative?: Maybe<Scalars['Int']>;
};

export type StatementScoreType = {
   __typename?: 'StatementScoreType';
  uuid: Scalars['String'];
  code: Scalars['String'];
  label: Scalars['String'];
  positive: Scalars['Float'];
  benchmarkPositive?: Maybe<Scalars['Float']>;
  inconsistent: Scalars['Float'];
  benchmarkInconsistent?: Maybe<Scalars['Float']>;
  negative: Scalars['Float'];
  benchmarkNegative?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  focus?: Maybe<QFocus>;
  residentFocus?: Maybe<QResidentFocus>;
  residentResultsGroup?: Maybe<QResidentResultsGroup>;
  children: Array<StatementScoreType>;
  /** Correlation coefficient to the overall positive index score */
  overallPositiveCorrelation?: Maybe<Scalars['Float']>;
};

/** An enumeration. */
export enum SurveyDistributionTypeEnum {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export type SurveyFilterTypeSettingInput = {
  dtCode: DataTypeCode;
  reportingType: FilterTypeReportingTypeEnum;
};

export type SurveyFilterTypeSettingType = {
   __typename?: 'SurveyFilterTypeSettingType';
  dtCode: DataTypeCode;
  reportingType: FilterTypeReportingTypeEnum;
};

export type SurveyInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: SurveyTypeEnum;
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  languages?: Maybe<Array<Scalars['String']>>;
  associatedSurveyUuid?: Maybe<Scalars['String']>;
  distributionType?: Maybe<SurveyDistributionTypeEnum>;
  participantDataSource?: Maybe<SurveyParticipantDataSourceEnum>;
  hasConfidentialResults?: Maybe<Scalars['Boolean']>;
  filterTypeSettings?: Maybe<Array<Maybe<SurveyFilterTypeSettingInput>>>;
};

/** An enumeration. */
export enum SurveyInviteMethodEnum {
  SMS_EMAIL = 'SMS_EMAIL',
  SMS_EMAIL_PHONE = 'SMS_EMAIL_PHONE',
  PHONE = 'PHONE'
}

export type SurveyNode = Node & {
   __typename?: 'SurveyNode';
  uuid: Scalars['String'];
  status: SurveyStatusEnum;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Survey start date and time in UTC */
  startDate: Scalars['DateTime'];
  /** Survey end date and time in UTC */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Should a password be required to view the response rate report? */
  reportPassword: Scalars['String'];
  /** How many SMS notifications can be sent per survey? */
  maxSmsNotifications: Scalars['Int'];
  /**
   * Keep the survey results confidential? If False,
   * it will show results with less than 5 responses and
   * enable the Individual Results tab in Insights.
   */
  hasConfidentialResults: Scalars['Boolean'];
  /** Include for US News? */
  includeForUsNews: Scalars['Boolean'];
  inviteMethod: SurveyInviteMethodEnum;
  scheduledNotifications: Array<ScheduledNotificationType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  insightsSurvey: InsightsSurveyNode;
  reportShareUrl: Scalars['String'];
  type: SurveyTypeEnum;
  productType: SurveyProductTypeEnum;
  minShowableResults: Scalars['Int'];
  /** Only used for Pulse Survey. */
  associatedSurvey?: Maybe<SurveyNode>;
  editable: Scalars['Boolean'];
  isRecurring: Scalars['Boolean'];
  allowedCategoriesForCustomQuestions: Array<QCategory>;
  allowedCategoriesForOptionalQuestions: Array<QCategory>;
  translationsRequired?: Maybe<Scalars['Boolean']>;
  numberOfMaxScheduledNotifications: Scalars['Int'];
  maxScheduledNotificationDate?: Maybe<Scalars['DateTime']>;
  languages: Array<LanguageType>;
  mustShowTakeActionNotification?: Maybe<Scalars['Boolean']>;
  welcomeMessages: Array<SurveyTextType>;
  defaultWelcomeMessages: Array<SurveyTextType>;
  distributionType?: Maybe<SurveyDistributionTypeEnum>;
  participantDataSource?: Maybe<SurveyParticipantDataSourceEnum>;
  dataTypeOptions: Array<DataTypeOptionType>;
  /** List of filter type uuids associated with participants on the survey */
  filterTypeUuids: Array<Scalars['String']>;
  filterTypeSettings: Array<SurveyFilterTypeSettingType>;
  participantsUpload?: Maybe<FileImportType>;
  clientsUpload?: Maybe<FileImportType>;
  participantTemplateUrl: Scalars['String'];
  clientTemplateUrl?: Maybe<Scalars['String']>;
  responseRate: ResponseRateType;
  previewUrl: Scalars['String'];
  surveyPortalUrl: Scalars['String'];
  intervalNotifications: Array<IntervalNotificationType>;
  groupsWithSurveyAccess: Array<GroupNode>;
  groupsSurveyResponseNotifications: Array<GroupsSurveyResponseNotificationType>;
  groupsAverageScoreNotifications: Array<GroupsSurveyAverageScoreNotificationType>;
  groupsQuestionScoreNotifications: Array<GroupsSurveyQuestionScoreNotificationType>;
  isDefaultClientBasedResponseRateReport: Scalars['Boolean'];
  takeActionCheckedSteps: Array<Maybe<Scalars['String']>>;
  insightsActionPlanPdfUrl?: Maybe<Scalars['String']>;
  defaultBenchmark?: Maybe<BenchmarkNode>;
  /** Does this survey include the testimonials question? */
  includesTestimonials: Scalars['Boolean'];
  /** Lookup whether this survey included all 3 loneliness questions and can be used to calculate a loneliness score. */
  includesLonelinessQuestions: Scalars['Boolean'];
  includesNpsQuestion: Scalars['Boolean'];
  includesLeftReasonQuestion: Scalars['Boolean'];
  hasCoreQRecommendResponses: Scalars['Boolean'];
  hasMissingTranslations: Scalars['Boolean'];
  /**
   * Does this survey have less than the minimum number of responses required to
   * show results? Available as a property for efficiency on bulk survey requests.
   */
  hasLessThanMinShowableResults: Scalars['Boolean'];
  hasIntervalNotifications: Scalars['Boolean'];
  hasSurveyResponseNotifications: Scalars['Boolean'];
  hasAverageScoreNotifications: Scalars['Boolean'];
  hasQuestionScoreNotifications: Scalars['Boolean'];
  numberOfLocationFilterValuesMissingRequiredData: Scalars['Int'];
  phoneScript?: Maybe<Scalars['String']>;
  missingPhonesErrorLevel: SurveyPhoneStatusErrorLevelEnum;
  hasIngestFailureReports: Scalars['Boolean'];
  eligibleParticipantSurveyConfigs: Array<EligibleParticipantSurveyConfigType>;
  usesSamplePoolDataSource: Scalars['Boolean'];
  reasonsForLeaving: Array<ReasonForLeaving>;
};


export type SurveyNodeInsightsSurveyArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  filters?: Maybe<Array<Scalars['String']>>;
};


export type SurveyNodeResponseRateArgs = {
  byClient?: Maybe<Scalars['Boolean']>;
};

export type SurveyNodeConnection = {
   __typename?: 'SurveyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SurveyNodeEdge>>;
};

/** A Relay edge containing a `SurveyNode` and its cursor. */
export type SurveyNodeEdge = {
   __typename?: 'SurveyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SurveyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum SurveyParticipantDataSourceEnum {
  DIRECT_ROSTER_IMPORT = 'DIRECT_ROSTER_IMPORT',
  SAMPLE_POOL = 'SAMPLE_POOL'
}

/** An enumeration. */
export enum SurveyPhoneStatusErrorLevelEnum {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  VALID = 'VALID'
}

/** An enumeration. */
export enum SurveyProductTypeEnum {
  EMPLOYEE = 'EMPLOYEE',
  RESIDENT = 'RESIDENT'
}

/** An enumeration. */
export enum SurveyResponseNotificationTypeEnum {
  FIRST_RESPONSE = 'FIRST_RESPONSE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY'
}

/** An enumeration. */
export enum SurveyScoreNotificationFrequencyEnum {
  DAILY = 'DAILY',
  WEEKLY_MONDAYS = 'WEEKLY_MONDAYS',
  WEEKLY_TUESDAYS = 'WEEKLY_TUESDAYS',
  WEEKLY_WEDNESDAYS = 'WEEKLY_WEDNESDAYS',
  WEEKLY_THURSDAYS = 'WEEKLY_THURSDAYS',
  WEEKLY_FRIDAYS = 'WEEKLY_FRIDAYS',
  WEEKLY_SATURDAYS = 'WEEKLY_SATURDAYS',
  WEEKLY_SUNDAYS = 'WEEKLY_SUNDAYS'
}

/** An enumeration. */
export enum SurveyScoreNotificationTypeEnum {
  LOW = 'LOW',
  HIGH = 'HIGH'
}

export type SurveysResponseRateTypeInput = {
  surveyUuid: Scalars['String'];
  responseRateType: ResponseRateTypeEnum;
};

/** An enumeration. */
export enum SurveyStatusEnum {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  LIVE = 'LIVE',
  CLOSED = 'CLOSED'
}

export type SurveySummaryType = {
   __typename?: 'SurveySummaryType';
  surveyPortalUrl: Scalars['String'];
  canSchedule: Scalars['Boolean'];
  canUnschedule: Scalars['Boolean'];
  numFinishedResponses?: Maybe<Scalars['Int']>;
  numTotalResponses?: Maybe<Scalars['Int']>;
  numLocationsMissingRequiredData?: Maybe<Scalars['Int']>;
  overallIndexScore?: Maybe<OverallScoreType>;
  design: DesignSectionType;
  questions: QuestionsSectionType;
  translations?: Maybe<TranslationsSectionType>;
  participants?: Maybe<ParticipantsSectionType>;
  notifications?: Maybe<NotificationsSectionType>;
  filterValues?: Maybe<FilterValuesSectionType>;
  settings?: Maybe<SettingsSectionType>;
};

export type SurveyTextInput = {
  key: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type SurveyTextType = {
   __typename?: 'SurveyTextType';
  uuid: Scalars['String'];
  text: Scalars['String'];
  key: Scalars['String'];
};

/** An enumeration. */
export enum SurveyTransitionTextKey {
  /** Third Party Confidential */
  THIRD_PARTY_CONFIDENTIAL = 'THIRD_PARTY_CONFIDENTIAL',
  /** Third Party */
  THIRD_PARTY = 'THIRD_PARTY',
  /** Verbatim Name */
  VERBATIM_NAME = 'VERBATIM_NAME',
  /** National Initiative */
  NATIONAL_INITIATIVE = 'NATIONAL_INITIATIVE',
  /** Experiences Next */
  EXPERIENCES_NEXT = 'EXPERIENCES_NEXT',
  /** Applicable Questions Continue */
  APPLICABLE_QUESTIONS_CONTINUE = 'APPLICABLE_QUESTIONS_CONTINUE',
  /** Know You Better Residents */
  KNOW_YOU_BETTER_RESIDENTS = 'KNOW_YOU_BETTER_RESIDENTS',
  /** Overall Experiences */
  OVERALL_EXPERIENCES = 'OVERALL_EXPERIENCES',
  /** General Feeling */
  GENERAL_FEELING = 'GENERAL_FEELING',
  /** Almost Done */
  ALMOST_DONE = 'ALMOST_DONE',
  /** Done */
  DONE = 'DONE',
  /** Verbatim Name Continue */
  VERBATIM_NAME_CONTINUE = 'VERBATIM_NAME_CONTINUE',
  /** Applicable Questions */
  APPLICABLE_QUESTIONS = 'APPLICABLE_QUESTIONS',
  /** Third Party Start */
  THIRD_PARTY_START = 'THIRD_PARTY_START',
  /** Know You Better Everyone */
  KNOW_YOU_BETTER_EVERYONE = 'KNOW_YOU_BETTER_EVERYONE'
}

export type SurveyTransitionTextType = {
   __typename?: 'SurveyTransitionTextType';
  key: SurveyTransitionTextKey;
  /** The text that will show up in the transition screen. */
  text: Scalars['String'];
  /** The text that will show up on the 'next' button. Default is 'Continue' */
  nextText: Scalars['String'];
};

/** An enumeration. */
export enum SurveyTypeEnum {
  TI = 'TI',
  PULSE = 'PULSE',
  CUSTOM = 'CUSTOM',
  MONTHLY = 'MONTHLY',
  END_OF_EMPLOYMENT = 'END_OF_EMPLOYMENT',
  RESIDENT_ENGAGEMENT = 'RESIDENT_ENGAGEMENT',
  RESIDENT_PULSE = 'RESIDENT_PULSE',
  RESIDENT_CUSTOM = 'RESIDENT_CUSTOM',
  RESIDENT_END_OF_SERVICE = 'RESIDENT_END_OF_SERVICE',
  RESIDENT_DISCHARGE = 'RESIDENT_DISCHARGE',
  RESIDENT_ENGAGEMENT_MONTHLY = 'RESIDENT_ENGAGEMENT_MONTHLY',
  RESIDENT_START_OF_SERVICE = 'RESIDENT_START_OF_SERVICE'
}

/** An enumeration. */
export enum TakeActionStepsEnum {
  UNDERSTAND_1 = 'UNDERSTAND_1',
  UNDERSTAND_2 = 'UNDERSTAND_2',
  UNDERSTAND_3 = 'UNDERSTAND_3',
  UNDERSTAND_4 = 'UNDERSTAND_4',
  UNDERSTAND_5 = 'UNDERSTAND_5',
  COMMUNICATE_1 = 'COMMUNICATE_1',
  COMMUNICATE_2 = 'COMMUNICATE_2',
  COMMUNICATE_3 = 'COMMUNICATE_3',
  COMMUNICATE_4 = 'COMMUNICATE_4',
  COMMUNICATE_5 = 'COMMUNICATE_5',
  IMPROVE_1 = 'IMPROVE_1',
  IMPROVE_2 = 'IMPROVE_2',
  IMPROVE_3 = 'IMPROVE_3',
  IMPROVE_4 = 'IMPROVE_4',
  IMPROVE_5 = 'IMPROVE_5',
  IMPROVE_6 = 'IMPROVE_6',
  IMPROVE_7 = 'IMPROVE_7',
  IMPROVE_8 = 'IMPROVE_8',
  IMPROVE_9 = 'IMPROVE_9',
  IMPROVE_10 = 'IMPROVE_10',
  IMPROVE_11 = 'IMPROVE_11',
  IMPROVE_12 = 'IMPROVE_12',
  IMPROVE_13 = 'IMPROVE_13',
  IMPROVE_14 = 'IMPROVE_14'
}

/** An enumeration. */
export enum TaskStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export type ToggleFavoriteSurvey = {
   __typename?: 'ToggleFavoriteSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

/** Update the list of action plans a user is following */
export type ToggleFollowActionPlan = {
   __typename?: 'ToggleFollowActionPlan';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type ToggleIsActive = {
   __typename?: 'ToggleIsActive';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

/** An enumeration. */
export enum TranslatedObjectTypeEnum {
  QUESTION = 'QUESTION',
  QUESTION_CHOICE = 'QUESTION_CHOICE',
  BENCHMARK_CONFIG = 'BENCHMARK_CONFIG',
  SURVEY_TEXT = 'SURVEY_TEXT',
  SERVICE_AREA = 'SERVICE_AREA'
}

export type TranslationInput = {
  uuid: Scalars['String'];
  objectUuid: Scalars['String'];
  objectType: TranslatedObjectTypeEnum;
  languageCode: Scalars['String'];
  text: Scalars['String'];
};

export type TranslationsSectionType = {
   __typename?: 'TranslationsSectionType';
  ready: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  warnings: Array<Scalars['String']>;
  data: Scalars['JSONString'];
  missing: Scalars['Int'];
  translated: Scalars['Int'];
};

export type TranslationType = {
   __typename?: 'TranslationType';
  uuid: Scalars['String'];
  /** The translated text in the language of the object */
  text: Scalars['String'];
  language: LanguageType;
  objectUuid: Scalars['String'];
  objectType: TranslatedObjectTypeEnum;
  englishText: Scalars['String'];
};

export type UpdateActionItemTask = {
   __typename?: 'UpdateActionItemTask';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type UpdateActionItemTaskComment = {
   __typename?: 'UpdateActionItemTaskComment';
  errors?: Maybe<Array<Scalars['String']>>;
  actionItemTask?: Maybe<ActionItemTaskNode>;
};

export type UpdateActionPlanSurvey = {
   __typename?: 'UpdateActionPlanSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  actionPlan?: Maybe<ActionPlanNode>;
};

export type UpdateCultureBriefImages = {
   __typename?: 'UpdateCultureBriefImages';
  errors?: Maybe<Array<Scalars['String']>>;
  cultureBrief?: Maybe<CultureBriefType>;
};

export type UpdateCultureBriefInput = {
  status: Scalars['String'];
  yearFounded?: Maybe<Scalars['DateTime']>;
  numberOfLocations?: Maybe<Scalars['Int']>;
  isSubsidiary?: Maybe<Scalars['Boolean']>;
  organizationName?: Maybe<Scalars['String']>;
  industrySubVertical?: Maybe<Scalars['String']>;
  organizationType?: Maybe<Scalars['String']>;
  mainTypeOfCare?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebookUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  instagramUrl?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  indeedUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  totalNumberOfEmployees?: Maybe<Scalars['Int']>;
  pastTotalNumberOfEmployees?: Maybe<Scalars['Int']>;
  totalApplicants?: Maybe<Scalars['Int']>;
  totalOpenRoles?: Maybe<Scalars['Int']>;
  recommendationsPercentage?: Maybe<Scalars['Int']>;
  averageNumberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEmployeesLeft?: Maybe<Scalars['Int']>;
  carbonEmissionsReductionPercentage?: Maybe<Scalars['Int']>;
  numberOfEmployeesWithHealthInsurance?: Maybe<Scalars['Int']>;
  numberOfEmployeesEligibleForHealthInsurance?: Maybe<Scalars['Int']>;
  employeeBenefits?: Maybe<Scalars['String']>;
  additionalEmployeeBenefits?: Maybe<Scalars['String']>;
  percentageOfEmployeesWithBigWages?: Maybe<Scalars['Int']>;
  percentageOfImmigrantEmployees?: Maybe<Scalars['Int']>;
  totalBoardComposition?: Maybe<Scalars['Int']>;
  totalBoardWomen?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesRace?: Maybe<Scalars['Int']>;
  totalBoardMinoritiesLgbt?: Maybe<Scalars['Int']>;
  totalCeoReportsComposition?: Maybe<Scalars['Int']>;
  totalCeoReportsWomen?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesRace?: Maybe<Scalars['Int']>;
  totalCeoReportsMinoritiesLgbt?: Maybe<Scalars['Int']>;
  hasDiversityEquity?: Maybe<Scalars['Boolean']>;
  diversityEquityDescription?: Maybe<Scalars['String']>;
  reasonsToWorkForCompany?: Maybe<Scalars['String']>;
  companyMission?: Maybe<Scalars['String']>;
  careersPageLink?: Maybe<Scalars['String']>;
  ceoFirstName?: Maybe<Scalars['String']>;
  ceoLastName?: Maybe<Scalars['String']>;
  ceoTitle?: Maybe<Scalars['String']>;
  ceoPhoneNumber?: Maybe<Scalars['String']>;
  ceoEmail?: Maybe<Scalars['String']>;
  mainContactFirstName?: Maybe<Scalars['String']>;
  mainContactLastName?: Maybe<Scalars['String']>;
  mainContactTitle?: Maybe<Scalars['String']>;
  mainContactPhoneNumber?: Maybe<Scalars['String']>;
  mainContactEmail?: Maybe<Scalars['String']>;
  marketingContactFirstName?: Maybe<Scalars['String']>;
  marketingContactLastName?: Maybe<Scalars['String']>;
  marketingContactTitle?: Maybe<Scalars['String']>;
  marketingContactPhoneNumber?: Maybe<Scalars['String']>;
  marketingContactEmail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCultureBriefPayload = {
   __typename?: 'UpdateCultureBriefPayload';
  cultureBrief?: Maybe<CultureBriefType>;
  errors?: Maybe<Array<Maybe<Errors>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomQuestion = {
   __typename?: 'UpdateCustomQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
  question?: Maybe<QuestionType>;
};

export type UpdateEligibleParticipantSurveyConfigs = {
   __typename?: 'UpdateEligibleParticipantSurveyConfigs';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateFilterValue = {
   __typename?: 'UpdateFilterValue';
  errors?: Maybe<Array<Scalars['String']>>;
  filterValue?: Maybe<FilterValueType>;
};

export type UpdateFilterValueArchived = {
   __typename?: 'UpdateFilterValueArchived';
  errors?: Maybe<Array<Scalars['String']>>;
  filterValue?: Maybe<FilterValueType>;
};

export type UpdateGroup = {
   __typename?: 'UpdateGroup';
  errors?: Maybe<Array<Scalars['String']>>;
  group?: Maybe<GroupNode>;
};

export type UpdateGroupsAccessToSurvey = {
   __typename?: 'UpdateGroupsAccessToSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateGroupsAverageScoreNotifications = {
   __typename?: 'UpdateGroupsAverageScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateGroupsQuestionScoreNotifications = {
   __typename?: 'UpdateGroupsQuestionScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateGroupsSurveyResponseNotifications = {
   __typename?: 'UpdateGroupsSurveyResponseNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateIntervalNotification = {
   __typename?: 'UpdateIntervalNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateOrganization = {
   __typename?: 'UpdateOrganization';
  errors?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<OrganizationType>;
};

export type UpdateOrganizationServiceAreas = {
   __typename?: 'UpdateOrganizationServiceAreas';
  errors?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<OrganizationType>;
};

export type UpdateOrganizationSurveyPortal = {
   __typename?: 'UpdateOrganizationSurveyPortal';
  errors?: Maybe<Array<Scalars['String']>>;
  organization?: Maybe<OrganizationType>;
};

export type UpdateParticipant = {
   __typename?: 'UpdateParticipant';
  errors?: Maybe<Array<Scalars['String']>>;
  participant?: Maybe<ParticipantType>;
};

export type UpdatePersonalInfoQuestion = {
   __typename?: 'UpdatePersonalInfoQuestion';
  errors?: Maybe<Array<Scalars['String']>>;
  question?: Maybe<QuestionType>;
};

export type UpdateReport = {
   __typename?: 'UpdateReport';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateResidentParticipant = {
   __typename?: 'UpdateResidentParticipant';
  errors?: Maybe<Array<Scalars['String']>>;
  response?: Maybe<PublicResponse>;
};

export type UpdateScheduledNotification = {
   __typename?: 'UpdateScheduledNotification';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

/** Mutation class to create or update an existing Survey instance */
export type UpdateSurvey = {
   __typename?: 'UpdateSurvey';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

/** Mutation class to update the Survey status. Basically called when launching a survey or unscheduling it */
export type UpdateSurveyStatus = {
   __typename?: 'UpdateSurveyStatus';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateTakeActionStepsMutation = {
   __typename?: 'UpdateTakeActionStepsMutation';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UpdateTranslations = {
   __typename?: 'UpdateTranslations';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type UpdateUser = {
   __typename?: 'UpdateUser';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserAppMessage = {
   __typename?: 'UpdateUserAppMessage';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserAverageScoreNotifications = {
   __typename?: 'UpdateUserAverageScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserHasSurveyResponseNotifications = {
   __typename?: 'UpdateUserHasSurveyResponseNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
};

export type UpdateUserQuestionScoreNotifications = {
   __typename?: 'UpdateUserQuestionScoreNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UpdateUserSurveyResponseNotifications = {
   __typename?: 'UpdateUserSurveyResponseNotifications';
  errors?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

/** Mutation class to create or update SurveyText instances (welcome message, welcome header etc) */
export type UpdateWelcomeMessages = {
   __typename?: 'UpdateWelcomeMessages';
  errors?: Maybe<Array<Scalars['String']>>;
  survey?: Maybe<SurveyNode>;
};

export type UserAppMessage = {
   __typename?: 'UserAppMessage';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  deleted?: Maybe<Scalars['DateTime']>;
  user: UserNode;
  kind: Scalars['String'];
  seen?: Maybe<Scalars['DateTime']>;
  contentObjects: Array<ContentObject>;
};

export type UserAverageScoreNotificationInput = {
  scoreType: SurveyScoreNotificationTypeEnum;
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
};

export type UserAverageScoreNotificationsInput = {
  surveyUuid: Scalars['String'];
  averageScoreNotifications: Array<UserAverageScoreNotificationInput>;
};

export type UserAverageScoreNotificationType = {
   __typename?: 'UserAverageScoreNotificationType';
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
  scoreType: SurveyScoreNotificationTypeEnum;
};

export type UserCommentMentionInput = {
  uuid: Scalars['String'];
  index: Scalars['Int'];
};

export type UserCommentMentionType = {
   __typename?: 'UserCommentMentionType';
  id: Scalars['ID'];
  /** Position in the comment at which the mention starts */
  index: Scalars['Int'];
  user: UserNode;
  comment: ActionItemTaskCommentNode;
};

/** An enumeration. */
export enum UserDownloadsEnum {
  RESPONSE_RATE_REPORT = 'RESPONSE_RATE_REPORT',
  STATEMENTS_EXPORT = 'STATEMENTS_EXPORT',
  NPS_EXPORT = 'NPS_EXPORT',
  PARTICIPANT_FAILURE_REPORT_EXPORT = 'PARTICIPANT_FAILURE_REPORT_EXPORT',
  INDIVIDUAL_RESULTS_EXPORT = 'INDIVIDUAL_RESULTS_EXPORT',
  CORE_Q_SATISFACTION_SCORES_EXPORT = 'CORE_Q_SATISFACTION_SCORES_EXPORT',
  COMMENTS_EXPORT = 'COMMENTS_EXPORT',
  TESTIMONIALS_EXPORT = 'TESTIMONIALS_EXPORT',
  CERTIFICATION_REPORT = 'CERTIFICATION_REPORT',
  CERTIFIED_LOCATIONS_EXPORT = 'CERTIFIED_LOCATIONS_EXPORT',
  ENGAGEMENT_REPORT = 'ENGAGEMENT_REPORT',
  PARTICIPANT_UPLOAD_TEMPLATE = 'PARTICIPANT_UPLOAD_TEMPLATE',
  CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE = 'CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE',
  RESIDENT_PARTICIPANT_UPLOAD_TEMPLATE = 'RESIDENT_PARTICIPANT_UPLOAD_TEMPLATE',
  RESIDENT_CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE = 'RESIDENT_CUSTOM_SURVEY_PARTICIPANT_UPLOAD_TEMPLATE',
  RESIDENT_CLIENT_UPLOAD_TEMPLATE = 'RESIDENT_CLIENT_UPLOAD_TEMPLATE',
  CUSTOM_SURVEY = 'CUSTOM_SURVEY',
  RESIDENT_CUSTOM_SURVEY = 'RESIDENT_CUSTOM_SURVEY',
  LONELINESS_REPORT = 'LONELINESS_REPORT',
  US_NEWS_SUMMARY_RESULTS = 'US_NEWS_SUMMARY_RESULTS',
  ADMIN_US_NEWS_RESULTS = 'ADMIN_US_NEWS_RESULTS',
  ADMIN_NPS_RAW_DATA = 'ADMIN_NPS_RAW_DATA',
  ADMIN_US_NEWS_LOCATIONS_AND_TESTIMONIALS = 'ADMIN_US_NEWS_LOCATIONS_AND_TESTIMONIALS',
  ADMIN_ORGANIZATION_USERS = 'ADMIN_ORGANIZATION_USERS',
  LTC_TREND_TRACKER = 'LTC_TREND_TRACKER',
  COMBINED_CONTACTS_TEMPLATE = 'COMBINED_CONTACTS_TEMPLATE',
  EMPLOYEE_CONTACTS_TEMPLATE = 'EMPLOYEE_CONTACTS_TEMPLATE',
  ACTION_PLANS = 'ACTION_PLANS'
}

export type UserNode = Node & {
   __typename?: 'UserNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  /** Timestamp of when the user was last active on the AI platform. */
  lastActivity?: Maybe<Scalars['DateTime']>;
  /** Will this user receive survey response notifications? */
  hasSurveyResponseNotifications: Scalars['Boolean'];
  appMessages: Array<UserAppMessage>;
  accessToSurveyProduct: Scalars['Boolean'];
  canCreateSurveys: Scalars['Boolean'];
  canAccessSurveySettings: Scalars['Boolean'];
  canManageCertifications: Scalars['Boolean'];
  canViewCertificationResults: Scalars['Boolean'];
  residentAccessToSurveyProduct: Scalars['Boolean'];
  residentCanCreateSurveys: Scalars['Boolean'];
  residentCanAccessSurveySettings: Scalars['Boolean'];
  canAccessLonelinessResults: Scalars['Boolean'];
  canUseAnalytics: Scalars['Boolean'];
  canInviteUsers: Scalars['Boolean'];
  canResetUsersPasswords: Scalars['Boolean'];
  canAccessDataStructure: Scalars['Boolean'];
  canAccessGeneralSettings: Scalars['Boolean'];
  canUseActionPlans: Scalars['Boolean'];
  canManageActionPlans: Scalars['Boolean'];
  uuid: Scalars['ID'];
  name: Scalars['String'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  organization: OrganizationType;
  filterPermissions: Array<Maybe<FilterTypeType>>;
  filters: Array<FilterTypeType>;
  insightsModules: Array<InsightsModulesEnum>;
  residentInsightsModules: Array<InsightsModulesEnum>;
  groups: Array<GroupNode>;
  surveyTypesAllowedToAdd: Array<SurveyTypeEnum>;
  followedActionPlanIds: Array<Scalars['String']>;
  favoriteSurveyIds: Array<Scalars['String']>;
  userResultNotifications: Array<UserResultNotificationsType>;
  canUploadCombinedContacts: Scalars['Boolean'];
  canUploadEmployeeContacts: Scalars['Boolean'];
};

export type UserNodeConnection = {
   __typename?: 'UserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
   __typename?: 'UserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UserQuestionScoreNotificationInput = {
  userNotificationUuid?: Maybe<Scalars['String']>;
  groupNotificationUuid: Scalars['String'];
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
};

export type UserQuestionScoreNotificationsInput = {
  surveyUuid: Scalars['String'];
  questionScoreNotifications: Array<UserQuestionScoreNotificationInput>;
};

export type UserQuestionScoreNotificationType = {
   __typename?: 'UserQuestionScoreNotificationType';
  uuid?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  frequency: SurveyScoreNotificationFrequencyEnum;
  groupNotification: GroupsSurveyQuestionScoreNotificationType;
};

export type UserResultNotificationsType = {
   __typename?: 'UserResultNotificationsType';
  survey: SurveyNode;
  surveyResponseNotificationTypes?: Maybe<Array<SurveyResponseNotificationTypeEnum>>;
  averageScoreNotifications?: Maybe<Array<UserAverageScoreNotificationType>>;
  questionScoreNotifications?: Maybe<Array<UserQuestionScoreNotificationType>>;
};

export type UserSurveyResponseNotificationInput = {
  surveyUuid: Scalars['String'];
  notificationTypes: Array<SurveyResponseNotificationTypeEnum>;
};

/** An enumeration. */
export enum UsStatesEnum {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}

export type VoluntaryDischargeGroupCountsType = {
   __typename?: 'VoluntaryDischargeGroupCountsType';
  label: Scalars['String'];
  voluntaryCount: Scalars['Int'];
  involuntaryCount: Scalars['Int'];
};

export type WelcomeMessage = {
   __typename?: 'WelcomeMessage';
  header: Scalars['String'];
  subheader: Scalars['String'];
  text: Scalars['String'];
};

export type CodeLookupMutationVariables = {
  organizationUuid: Scalars['String'];
  code: Scalars['String'];
  surveyProductType: SurveyProductTypeEnum;
};


export type CodeLookupMutation = (
  { __typename?: 'Mutation' }
  & { codeLookup?: Maybe<(
    { __typename?: 'CodeLookup' }
    & Pick<CodeLookup, 'errors'>
    & { surveys?: Maybe<Array<(
      { __typename?: 'PublicSurveyType' }
      & Pick<PublicSurveyType, 'participantId' | 'surveySubmitted' | 'surveyName'>
    )>> }
  )> }
);

export type LanguageFragment = (
  { __typename?: 'LanguageType' }
  & Pick<LanguageType, 'code' | 'name' | 'nameTranslated'>
);

export type LocationFragment = (
  { __typename?: 'FilterValueType' }
  & Pick<FilterValueType, 'displayName' | 'city' | 'state'>
  & { onlineReviewSites?: Maybe<Array<(
    { __typename?: 'FilterValueReviewSiteType' }
    & Pick<FilterValueReviewSiteType, 'reviewSiteName' | 'externalId'>
  )>> }
);

export type OrganizationBySlugQueryVariables = {
  slug: Scalars['String'];
  surveyProductType: SurveyProductTypeEnum;
};


export type OrganizationBySlugQuery = (
  { __typename?: 'Query' }
  & { organizationBySlug?: Maybe<(
    { __typename?: 'PublicOrganization' }
    & Pick<PublicOrganization, 'uuid' | 'hasLiveSurvey' | 'residentSurveyCodeName'>
    & { employeeSurveyCodeName: PublicOrganization['surveyCodeName'], employeeHelpText: PublicOrganization['surveyCodeHelpText'], residentHelpText: PublicOrganization['residentSurveyCodeHelpText'] }
  )>, organizationAnalyticsIdentifiers?: Maybe<(
    { __typename?: 'AnalyticsIdentifiersType' }
    & Pick<AnalyticsIdentifiersType, 'isTestAccount' | 'orgName' | 'residentSolutionName' | 'solutionName'>
  )> }
);

export type PublicConstantsQueryVariables = {};


export type PublicConstantsQuery = (
  { __typename?: 'Query' }
  & { publicConstants: (
    { __typename?: 'PublicConstants' }
    & { languages: Array<(
      { __typename?: 'LanguageType' }
      & LanguageFragment
    )>, questionInstructions: Array<(
      { __typename?: 'AgentInstructionType' }
      & Pick<AgentInstructionType, 'text' | 'kind'>
      & { question?: Maybe<(
        { __typename?: 'QuestionType' }
        & Pick<QuestionType, 'benchmarkCode'>
      )> }
    )> }
  ) }
);

export type PublicOpenLinkSurveyQueryVariables = {
  surveyHashId: Scalars['String'];
};


export type PublicOpenLinkSurveyQuery = (
  { __typename?: 'Query' }
  & { surveyById?: Maybe<(
    { __typename?: 'PublicSurvey' }
    & Pick<PublicSurvey, 'type' | 'status' | 'startDate'>
    & { welcomeMessage: (
      { __typename?: 'WelcomeMessage' }
      & Pick<WelcomeMessage, 'header' | 'subheader' | 'text'>
    ), languages: Array<(
      { __typename?: 'LanguageType' }
      & LanguageFragment
    )>, translations: Array<(
      { __typename?: 'PublicTranslation' }
      & TranslationFragment
    )>, participant: (
      { __typename?: 'PublicParticipant' }
      & Pick<PublicParticipant, 'templates'>
      & { questions: Array<(
        { __typename?: 'PublicQuestion' }
        & Pick<PublicQuestion, 'benchmarkCode' | 'code' | 'text' | 'kind'>
        & { choices?: Maybe<Array<(
          { __typename?: 'PublicChoice' }
          & Pick<PublicChoice, 'code' | 'text'>
        )>> }
      )> }
    ) }
  )> }
);

export type QuestionFragment = (
  { __typename?: 'PublicQuestion' }
  & Pick<PublicQuestion, 'visible' | 'code' | 'text' | 'benchmarkCode' | 'kind' | 'residentFocus'>
  & { dependsOnChoices?: Maybe<Array<(
    { __typename?: 'PublicChoice' }
    & Pick<PublicChoice, 'code'>
    & { question: (
      { __typename?: 'PublicQuestion' }
      & Pick<PublicQuestion, 'code'>
    ) }
  )>>, choices?: Maybe<Array<(
    { __typename?: 'PublicChoice' }
    & Pick<PublicChoice, 'code' | 'text' | 'value' | 'benchmarkCode'>
    & { childrenQuestions?: Maybe<Array<(
      { __typename?: 'PublicQuestion' }
      & Pick<PublicQuestion, 'code'>
    )>> }
  )>> }
);

export type SavePersonalInfoResponsesMutationVariables = {
  surveyHashId: Scalars['String'];
  responses: Array<QuestionResponse>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clientFirstName?: Maybe<Scalars['String']>;
  clientLastName?: Maybe<Scalars['String']>;
};


export type SavePersonalInfoResponsesMutation = (
  { __typename?: 'Mutation' }
  & { savePersonalInfoResponses?: Maybe<(
    { __typename?: 'SavePersonalInfoResponses' }
    & Pick<SavePersonalInfoResponses, 'errors' | 'participantHashId'>
  )> }
);

export type SaveResponsesMutationVariables = {
  participantId: Scalars['String'];
  responses?: Maybe<Array<Maybe<QuestionResponse>>>;
  submitted?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  source?: Maybe<ParticipantSourceTypeInput>;
};


export type SaveResponsesMutation = (
  { __typename?: 'Mutation' }
  & { saveResponses?: Maybe<(
    { __typename?: 'SaveResponses' }
    & Pick<SaveResponses, 'errors'>
  )> }
);

export type SurveyByIdQueryVariables = {
  surveyId: Scalars['String'];
};


export type SurveyByIdQuery = (
  { __typename?: 'Query' }
  & { surveyById?: Maybe<(
    { __typename?: 'PublicSurvey' }
    & { organization: (
      { __typename?: 'PublicOrganization' }
      & Pick<PublicOrganization, 'locationFilterTypeUuid'>
    ), participant: (
      { __typename?: 'PublicParticipant' }
      & Pick<PublicParticipant, 'levelOfCare' | 'templates'>
      & { location: (
        { __typename?: 'FilterValueType' }
        & LocationFragment
      ), questions: Array<(
        { __typename?: 'PublicQuestion' }
        & QuestionFragment
      )> }
    ) }
    & SurveyFragment
  )> }
);

export type SurveyByParticipantIdQueryVariables = {
  participantId: Scalars['String'];
  source?: Maybe<ParticipantSourceTypeInput>;
};


export type SurveyByParticipantIdQuery = (
  { __typename?: 'Query' }
  & { surveyByParticipantId?: Maybe<(
    { __typename?: 'PublicSurvey' }
    & { participant: (
      { __typename?: 'PublicParticipant' }
      & Pick<PublicParticipant, 'surveyStarted' | 'surveySubmitted' | 'isExpired' | 'templates' | 'levelOfCare'>
      & { questions: Array<(
        { __typename?: 'PublicQuestion' }
        & QuestionFragment
      )>, responses: Array<(
        { __typename?: 'PublicResponse' }
        & Pick<PublicResponse, 'code' | 'response'>
      )>, location: (
        { __typename?: 'FilterValueType' }
        & LocationFragment
      ), duplicatedContactInfo: Array<(
        { __typename?: 'ParticipantInfo' }
        & Pick<ParticipantInfo, 'participantId' | 'fullName' | 'residentRespondentType' | 'jobTitle' | 'location' | 'department'>
      )> }
    ) }
    & SurveyFragment
  )>, participantAnalyticsIdentifiers?: Maybe<(
    { __typename?: 'AnalyticsIdentifiersType' }
    & Pick<AnalyticsIdentifiersType, 'isTestAccount' | 'orgName' | 'residentSolutionName' | 'solutionName' | 'birthYearRange' | 'levelOfCare' | 'residentRespondentType'>
  )> }
);

export type SurveyFragment = (
  { __typename?: 'PublicSurvey' }
  & Pick<PublicSurvey, 'startDate' | 'type' | 'productType' | 'isOpenLinkSurvey' | 'hasConfidentialResults'>
  & { welcomeMessage: (
    { __typename?: 'WelcomeMessage' }
    & Pick<WelcomeMessage, 'header' | 'subheader' | 'text'>
  ), languages: Array<(
    { __typename?: 'LanguageType' }
    & LanguageFragment
  )>, translations: Array<(
    { __typename?: 'PublicTranslation' }
    & TranslationFragment
  )>, transitionTexts: Array<(
    { __typename?: 'SurveyTransitionTextType' }
    & Pick<SurveyTransitionTextType, 'key' | 'text' | 'nextText'>
  )> }
);

export type TranslationFragment = (
  { __typename?: 'PublicTranslation' }
  & Pick<PublicTranslation, 'text' | 'englishText'>
  & { language: (
    { __typename?: 'LanguageType' }
    & Pick<LanguageType, 'code'>
  ) }
);

export type UpdateResidentParticipantMutationVariables = {
  participantId: Scalars['String'];
  questionCode?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['Boolean']>;
  fingerprint?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  source?: Maybe<ParticipantSourceTypeInput>;
};


export type UpdateResidentParticipantMutation = (
  { __typename?: 'Mutation' }
  & { updateResidentParticipant?: Maybe<(
    { __typename?: 'UpdateResidentParticipant' }
    & Pick<UpdateResidentParticipant, 'errors'>
    & { response?: Maybe<(
      { __typename?: 'PublicResponse' }
      & Pick<PublicResponse, 'code' | 'response'>
    )> }
  )> }
);

export const LocationFragmentDoc = gql`
    fragment Location on FilterValueType {
  displayName
  city
  state
  onlineReviewSites {
    reviewSiteName
    externalId
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on PublicQuestion {
  visible
  code
  text
  benchmarkCode
  kind
  residentFocus
  dependsOnChoices {
    code
    question {
      code
    }
  }
  choices {
    code
    text
    value
    benchmarkCode
    childrenQuestions {
      code
    }
  }
}
    `;
export const LanguageFragmentDoc = gql`
    fragment Language on LanguageType {
  code
  name
  nameTranslated
}
    `;
export const TranslationFragmentDoc = gql`
    fragment Translation on PublicTranslation {
  text
  language {
    code
  }
  englishText
}
    `;
export const SurveyFragmentDoc = gql`
    fragment Survey on PublicSurvey {
  startDate
  welcomeMessage {
    header
    subheader
    text
  }
  type
  productType
  isOpenLinkSurvey
  hasConfidentialResults
  languages {
    ...Language
  }
  translations {
    ...Translation
  }
  transitionTexts {
    key
    text
    nextText
  }
}
    ${LanguageFragmentDoc}
${TranslationFragmentDoc}`;
export const CodeLookupDocument = gql`
    mutation codeLookup($organizationUuid: String!, $code: String!, $surveyProductType: SurveyProductTypeEnum!) {
  codeLookup(organizationUuid: $organizationUuid, code: $code, surveyProductType: $surveyProductType) {
    errors
    surveys {
      participantId
      surveySubmitted
      surveyName
    }
  }
}
    `;
export type CodeLookupMutationFn = Apollo.MutationFunction<CodeLookupMutation, CodeLookupMutationVariables>;

/**
 * __useCodeLookupMutation__
 *
 * To run a mutation, you first call `useCodeLookupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCodeLookupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [codeLookupMutation, { data, loading, error }] = useCodeLookupMutation({
 *   variables: {
 *      organizationUuid: // value for 'organizationUuid'
 *      code: // value for 'code'
 *      surveyProductType: // value for 'surveyProductType'
 *   },
 * });
 */
export function useCodeLookupMutation(baseOptions?: Apollo.MutationHookOptions<CodeLookupMutation, CodeLookupMutationVariables>) {
        return Apollo.useMutation<CodeLookupMutation, CodeLookupMutationVariables>(CodeLookupDocument, baseOptions);
      }
export type CodeLookupMutationHookResult = ReturnType<typeof useCodeLookupMutation>;
export type CodeLookupMutationResult = Apollo.MutationResult<CodeLookupMutation>;
export type CodeLookupMutationOptions = Apollo.BaseMutationOptions<CodeLookupMutation, CodeLookupMutationVariables>;
export const OrganizationBySlugDocument = gql`
    query organizationBySlug($slug: String!, $surveyProductType: SurveyProductTypeEnum!) {
  organizationBySlug(slug: $slug, surveyProductType: $surveyProductType) {
    uuid
    hasLiveSurvey
    employeeSurveyCodeName: surveyCodeName
    employeeHelpText: surveyCodeHelpText
    residentSurveyCodeName
    residentHelpText: residentSurveyCodeHelpText
  }
  organizationAnalyticsIdentifiers(slug: $slug) {
    isTestAccount
    orgName
    residentSolutionName
    solutionName
  }
}
    `;

/**
 * __useOrganizationBySlugQuery__
 *
 * To run a query within a React component, call `useOrganizationBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      surveyProductType: // value for 'surveyProductType'
 *   },
 * });
 */
export function useOrganizationBySlugQuery(baseOptions: Apollo.QueryHookOptions<OrganizationBySlugQuery, OrganizationBySlugQueryVariables>) {
        return Apollo.useQuery<OrganizationBySlugQuery, OrganizationBySlugQueryVariables>(OrganizationBySlugDocument, baseOptions);
      }
export function useOrganizationBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationBySlugQuery, OrganizationBySlugQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationBySlugQuery, OrganizationBySlugQueryVariables>(OrganizationBySlugDocument, baseOptions);
        }
export type OrganizationBySlugQueryHookResult = ReturnType<typeof useOrganizationBySlugQuery>;
export type OrganizationBySlugLazyQueryHookResult = ReturnType<typeof useOrganizationBySlugLazyQuery>;
export type OrganizationBySlugQueryResult = Apollo.QueryResult<OrganizationBySlugQuery, OrganizationBySlugQueryVariables>;
export const PublicConstantsDocument = gql`
    query PublicConstants {
  publicConstants {
    languages {
      ...Language
    }
    questionInstructions {
      text
      kind
      question {
        benchmarkCode
      }
    }
  }
}
    ${LanguageFragmentDoc}`;

/**
 * __usePublicConstantsQuery__
 *
 * To run a query within a React component, call `usePublicConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicConstantsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicConstantsQuery(baseOptions?: Apollo.QueryHookOptions<PublicConstantsQuery, PublicConstantsQueryVariables>) {
        return Apollo.useQuery<PublicConstantsQuery, PublicConstantsQueryVariables>(PublicConstantsDocument, baseOptions);
      }
export function usePublicConstantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicConstantsQuery, PublicConstantsQueryVariables>) {
          return Apollo.useLazyQuery<PublicConstantsQuery, PublicConstantsQueryVariables>(PublicConstantsDocument, baseOptions);
        }
export type PublicConstantsQueryHookResult = ReturnType<typeof usePublicConstantsQuery>;
export type PublicConstantsLazyQueryHookResult = ReturnType<typeof usePublicConstantsLazyQuery>;
export type PublicConstantsQueryResult = Apollo.QueryResult<PublicConstantsQuery, PublicConstantsQueryVariables>;
export const PublicOpenLinkSurveyDocument = gql`
    query PublicOpenLinkSurvey($surveyHashId: String!) {
  surveyById(surveyId: $surveyHashId) {
    type
    status
    startDate
    welcomeMessage {
      header
      subheader
      text
    }
    languages {
      ...Language
    }
    translations {
      ...Translation
    }
    participant {
      templates
      questions(personalInfoQuestionsOnly: true) {
        benchmarkCode
        code
        text
        kind
        choices {
          code
          text
        }
      }
    }
  }
}
    ${LanguageFragmentDoc}
${TranslationFragmentDoc}`;

/**
 * __usePublicOpenLinkSurveyQuery__
 *
 * To run a query within a React component, call `usePublicOpenLinkSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicOpenLinkSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicOpenLinkSurveyQuery({
 *   variables: {
 *      surveyHashId: // value for 'surveyHashId'
 *   },
 * });
 */
export function usePublicOpenLinkSurveyQuery(baseOptions: Apollo.QueryHookOptions<PublicOpenLinkSurveyQuery, PublicOpenLinkSurveyQueryVariables>) {
        return Apollo.useQuery<PublicOpenLinkSurveyQuery, PublicOpenLinkSurveyQueryVariables>(PublicOpenLinkSurveyDocument, baseOptions);
      }
export function usePublicOpenLinkSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicOpenLinkSurveyQuery, PublicOpenLinkSurveyQueryVariables>) {
          return Apollo.useLazyQuery<PublicOpenLinkSurveyQuery, PublicOpenLinkSurveyQueryVariables>(PublicOpenLinkSurveyDocument, baseOptions);
        }
export type PublicOpenLinkSurveyQueryHookResult = ReturnType<typeof usePublicOpenLinkSurveyQuery>;
export type PublicOpenLinkSurveyLazyQueryHookResult = ReturnType<typeof usePublicOpenLinkSurveyLazyQuery>;
export type PublicOpenLinkSurveyQueryResult = Apollo.QueryResult<PublicOpenLinkSurveyQuery, PublicOpenLinkSurveyQueryVariables>;
export const SavePersonalInfoResponsesDocument = gql`
    mutation SavePersonalInfoResponses($surveyHashId: String!, $responses: [QuestionResponse!]!, $firstName: String, $lastName: String, $clientFirstName: String, $clientLastName: String) {
  savePersonalInfoResponses(surveyHashId: $surveyHashId, responses: $responses, firstName: $firstName, lastName: $lastName, clientFirstName: $clientFirstName, clientLastName: $clientLastName) {
    errors
    participantHashId
  }
}
    `;
export type SavePersonalInfoResponsesMutationFn = Apollo.MutationFunction<SavePersonalInfoResponsesMutation, SavePersonalInfoResponsesMutationVariables>;

/**
 * __useSavePersonalInfoResponsesMutation__
 *
 * To run a mutation, you first call `useSavePersonalInfoResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePersonalInfoResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePersonalInfoResponsesMutation, { data, loading, error }] = useSavePersonalInfoResponsesMutation({
 *   variables: {
 *      surveyHashId: // value for 'surveyHashId'
 *      responses: // value for 'responses'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      clientFirstName: // value for 'clientFirstName'
 *      clientLastName: // value for 'clientLastName'
 *   },
 * });
 */
export function useSavePersonalInfoResponsesMutation(baseOptions?: Apollo.MutationHookOptions<SavePersonalInfoResponsesMutation, SavePersonalInfoResponsesMutationVariables>) {
        return Apollo.useMutation<SavePersonalInfoResponsesMutation, SavePersonalInfoResponsesMutationVariables>(SavePersonalInfoResponsesDocument, baseOptions);
      }
export type SavePersonalInfoResponsesMutationHookResult = ReturnType<typeof useSavePersonalInfoResponsesMutation>;
export type SavePersonalInfoResponsesMutationResult = Apollo.MutationResult<SavePersonalInfoResponsesMutation>;
export type SavePersonalInfoResponsesMutationOptions = Apollo.BaseMutationOptions<SavePersonalInfoResponsesMutation, SavePersonalInfoResponsesMutationVariables>;
export const SaveResponsesDocument = gql`
    mutation SaveResponses($participantId: String!, $responses: [QuestionResponse], $submitted: Boolean, $fingerprint: String, $source: ParticipantSourceTypeInput) {
  saveResponses(participantId: $participantId, responses: $responses, submitted: $submitted, fingerprint: $fingerprint, source: $source) {
    errors
  }
}
    `;
export type SaveResponsesMutationFn = Apollo.MutationFunction<SaveResponsesMutation, SaveResponsesMutationVariables>;

/**
 * __useSaveResponsesMutation__
 *
 * To run a mutation, you first call `useSaveResponsesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResponsesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResponsesMutation, { data, loading, error }] = useSaveResponsesMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      responses: // value for 'responses'
 *      submitted: // value for 'submitted'
 *      fingerprint: // value for 'fingerprint'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSaveResponsesMutation(baseOptions?: Apollo.MutationHookOptions<SaveResponsesMutation, SaveResponsesMutationVariables>) {
        return Apollo.useMutation<SaveResponsesMutation, SaveResponsesMutationVariables>(SaveResponsesDocument, baseOptions);
      }
export type SaveResponsesMutationHookResult = ReturnType<typeof useSaveResponsesMutation>;
export type SaveResponsesMutationResult = Apollo.MutationResult<SaveResponsesMutation>;
export type SaveResponsesMutationOptions = Apollo.BaseMutationOptions<SaveResponsesMutation, SaveResponsesMutationVariables>;
export const SurveyByIdDocument = gql`
    query SurveyById($surveyId: String!) {
  surveyById(surveyId: $surveyId) {
    ...Survey
    organization {
      locationFilterTypeUuid
    }
    participant {
      levelOfCare
      location {
        ...Location
      }
      templates
      questions {
        ...Question
      }
    }
  }
}
    ${SurveyFragmentDoc}
${LocationFragmentDoc}
${QuestionFragmentDoc}`;

/**
 * __useSurveyByIdQuery__
 *
 * To run a query within a React component, call `useSurveyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyByIdQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useSurveyByIdQuery(baseOptions: Apollo.QueryHookOptions<SurveyByIdQuery, SurveyByIdQueryVariables>) {
        return Apollo.useQuery<SurveyByIdQuery, SurveyByIdQueryVariables>(SurveyByIdDocument, baseOptions);
      }
export function useSurveyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyByIdQuery, SurveyByIdQueryVariables>) {
          return Apollo.useLazyQuery<SurveyByIdQuery, SurveyByIdQueryVariables>(SurveyByIdDocument, baseOptions);
        }
export type SurveyByIdQueryHookResult = ReturnType<typeof useSurveyByIdQuery>;
export type SurveyByIdLazyQueryHookResult = ReturnType<typeof useSurveyByIdLazyQuery>;
export type SurveyByIdQueryResult = Apollo.QueryResult<SurveyByIdQuery, SurveyByIdQueryVariables>;
export const SurveyByParticipantIdDocument = gql`
    query SurveyByParticipantId($participantId: String!, $source: ParticipantSourceTypeInput) {
  surveyByParticipantId(participantId: $participantId, source: $source) {
    ...Survey
    participant {
      surveyStarted
      surveySubmitted
      isExpired
      templates
      questions {
        ...Question
      }
      responses {
        code
        response
      }
      levelOfCare
      location {
        ...Location
      }
      duplicatedContactInfo {
        participantId
        fullName
        residentRespondentType
        jobTitle
        location
        department
      }
    }
  }
  participantAnalyticsIdentifiers(participantId: $participantId) {
    isTestAccount
    orgName
    residentSolutionName
    solutionName
    birthYearRange
    levelOfCare
    residentRespondentType
  }
}
    ${SurveyFragmentDoc}
${QuestionFragmentDoc}
${LocationFragmentDoc}`;

/**
 * __useSurveyByParticipantIdQuery__
 *
 * To run a query within a React component, call `useSurveyByParticipantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyByParticipantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyByParticipantIdQuery({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useSurveyByParticipantIdQuery(baseOptions: Apollo.QueryHookOptions<SurveyByParticipantIdQuery, SurveyByParticipantIdQueryVariables>) {
        return Apollo.useQuery<SurveyByParticipantIdQuery, SurveyByParticipantIdQueryVariables>(SurveyByParticipantIdDocument, baseOptions);
      }
export function useSurveyByParticipantIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyByParticipantIdQuery, SurveyByParticipantIdQueryVariables>) {
          return Apollo.useLazyQuery<SurveyByParticipantIdQuery, SurveyByParticipantIdQueryVariables>(SurveyByParticipantIdDocument, baseOptions);
        }
export type SurveyByParticipantIdQueryHookResult = ReturnType<typeof useSurveyByParticipantIdQuery>;
export type SurveyByParticipantIdLazyQueryHookResult = ReturnType<typeof useSurveyByParticipantIdLazyQuery>;
export type SurveyByParticipantIdQueryResult = Apollo.QueryResult<SurveyByParticipantIdQuery, SurveyByParticipantIdQueryVariables>;
export const UpdateResidentParticipantDocument = gql`
    mutation UpdateResidentParticipant($participantId: String!, $questionCode: String, $response: String, $submitted: Boolean, $fingerprint: String, $timestamp: DateTime, $source: ParticipantSourceTypeInput) {
  updateResidentParticipant(participantId: $participantId, questionCode: $questionCode, response: $response, submitted: $submitted, fingerprint: $fingerprint, timestamp: $timestamp, source: $source) {
    errors
    response {
      code
      response
    }
  }
}
    `;
export type UpdateResidentParticipantMutationFn = Apollo.MutationFunction<UpdateResidentParticipantMutation, UpdateResidentParticipantMutationVariables>;

/**
 * __useUpdateResidentParticipantMutation__
 *
 * To run a mutation, you first call `useUpdateResidentParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResidentParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResidentParticipantMutation, { data, loading, error }] = useUpdateResidentParticipantMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *      questionCode: // value for 'questionCode'
 *      response: // value for 'response'
 *      submitted: // value for 'submitted'
 *      fingerprint: // value for 'fingerprint'
 *      timestamp: // value for 'timestamp'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useUpdateResidentParticipantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResidentParticipantMutation, UpdateResidentParticipantMutationVariables>) {
        return Apollo.useMutation<UpdateResidentParticipantMutation, UpdateResidentParticipantMutationVariables>(UpdateResidentParticipantDocument, baseOptions);
      }
export type UpdateResidentParticipantMutationHookResult = ReturnType<typeof useUpdateResidentParticipantMutation>;
export type UpdateResidentParticipantMutationResult = Apollo.MutationResult<UpdateResidentParticipantMutation>;
export type UpdateResidentParticipantMutationOptions = Apollo.BaseMutationOptions<UpdateResidentParticipantMutation, UpdateResidentParticipantMutationVariables>;